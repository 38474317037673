import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderSearchSelect, renderField, renderSelectField, renderLabeledField2, renderTextArea } from '../../Utils/renderField/renderField';
import renderDatePicker from '../../Utils/renderField/renderDatePicker'
import { connect } from 'react-redux';
import { loadMainVehicles, loadFleetDrivers } from '../../../../redux/modules/expenseTickets';

const BackupDriverForm = (props) => {
  const { handleSubmit, formValues } = props;
  const payType = (formValues && formValues.payType) ? formValues.payType : '';

  return (
    <form onSubmit={handleSubmit}>
      <div className="container p-4">
        <div className="row mb-3">
          <div className="col-md-6">
            <strong>Driver</strong>
            <Field
              name="driver"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadFleetDrivers}
              allKey
            />
          </div>
          <div className="col-md-6">
            <strong>Vehicle</strong>
            <Field
              name="vehicle"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadMainVehicles}
              allKey
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
          <strong>Trip ID</strong>
            <Field
              name="tripId"
              component={renderLabeledField2}
              type="text"
              placeholder="85231"
              className="p-0"
            />
          </div>
          <div className="col-md-6">
            <strong>Pay Type</strong>
            <Field
              name="payType"
              component={renderSelectField}
              options={[
                { value: "fromTrip", label: "From Trip" },
                { value: "fixedHours", label: "Fixed Hours" },
              ]}
              placeholder="Pay Type"
              className="p-0"
              isFormControlActive={false}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 ">
          <strong>Start Date/Time</strong>
            <Field
              name="startDate"
              component={renderDatePicker}
              placeholder="12/31/2024 12:00 PM"
              numberOfMonths={1}
              showButton={false}
              className="w-100"
              disabled={payType !== 'fixedHours'}
            />
          </div>
          <div className="col-md-6 ">
            <strong>Fixed Hours</strong>
            <Field
              name="fixedHours"
              component={renderLabeledField2}
              type="text"
              placeholder="Hours"
              className="w-100"
              disabled={payType !== 'fixedHours'}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className='col-12'>
            <strong>Notes</strong>
            <Field
              name="notes"
              component={renderTextArea}
              rows={4}
              placeholder="Notes..."
              className="w-100"
            />
          </div>
        </div>

        <div className="text-center mt-2">
          <button type="submit" className="btn btn-primary btn-lg w-100">Save</button>
        </div>
      </div>
    </form>
  );
};


const mapStateToProps = (state) => ({
  formValues: (state.form.backupDriverForm && state.form.backupDriverForm.values) ? state.form.backupDriverForm.values : {}
});

export default connect(mapStateToProps)(reduxForm({
  form: 'backupDriverForm',
  validate: (data) => {
    const errors = {}
    if (!data.driver) errors.driver = "Required Field";
    if (!data.vehicle) errors.vehicle = "Required Field";
    if (!data.payType) errors.payType = "Required Field";
    if (!data.tripId) errors.tripId = "Required Field";
    if (data.payType === 'fixedHours' && !data.startDate) {
      errors.startDate = "Required Field";
    }
    if (data.payType === 'fixedHours' && !data.fixedHours) {
      errors.fixedHours = "Required Field";
    }
    
    return errors
  }
})(BackupDriverForm));
