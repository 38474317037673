import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/liveryEdition';
import LoadMask from '../../Utils/LoadMask';
import { formatFlightStatus } from './createForm';


const NoModalVerify = (props) => {
    const {
        loadingVerifier,
        xmlFlightVerified,
        modalVerifyFlight,
        formValues,
        setModalVerifyFlight,
        useVerifyFlight,
        useOriginalVerifyFlight,
        customOriginalVerifyFlight,
        customVerifyFlight,
    } = props;

    const originalVerifyFlight = () => {
        if (customOriginalVerifyFlight) customOriginalVerifyFlight();
        else useOriginalVerifyFlight();
    };

    const verifyFlight = () => {
        if (customVerifyFlight) customVerifyFlight();
        else useVerifyFlight();
    };

    return modalVerifyFlight ? (
        <div className="w-100">
            <LoadMask loading={loadingVerifier} blur>
                <div className="p-4">
                    {Object.keys(xmlFlightVerified).length > 0 &&
                    !xmlFlightVerified.noInfo ? (
                        <React.Fragment>
                            <div className="mb-4 border rounded border-dark p-3 d-flex flex-column flex-sm-row">
                                <div className="flex-1 d-flex flex-column align-items-start">
                                    <label>{xmlFlightVerified.flight.airline.code} {xmlFlightVerified.flight.flightNumber}</label>
                                    <label>{xmlFlightVerified.departure.airport} - {xmlFlightVerified.arrival.airport}</label>
                                </div>
                                <div className="flex-1 d-flex flex-column align-items-end">
                                    <label className="bold text-uppercase">{formatFlightStatus(xmlFlightVerified.flight.flightStatus)}</label>
                                    {(['NoTakeOffInfo', 'Unknown'].includes(xmlFlightVerified.flight.flightStatus)) ? (
                                        <div className="w-100 my-1 d-flex flex-column align-items-center text-primary p-2"
                                            style={{ background: '#ebebeb' }}
                                        >
                                            <label> NA </label>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {(xmlFlightVerified.departure.actual || xmlFlightVerified.departure.updated) && (
                                                <div className={`w-100 my-1 d-flex flex-column align-items-center ${
                                                        xmlFlightVerified.departure.updated
                                                            ? 'text-primary'
                                                            : xmlFlightVerified.departure.actual ? 'text-success' : ''
                                                    } p-2`}
                                                    style={{ background: '#ebebeb' }}
                                                >
                                                    <label>
                                                        {xmlFlightVerified.departure.actual ? 'ACTUAL'
                                                            : xmlFlightVerified.departure.updated && 'UPDATE'
                                                        }
                                                    </label>
                                                    <label>
                                                        {`DEP: ${xmlFlightVerified.departure.actual
                                                            ? xmlFlightVerified.departure.actual.time
                                                            : xmlFlightVerified.departure.updated
                                                                ? xmlFlightVerified.departure.updated.time
                                                                : xmlFlightVerified.departure.scheduled.time} ARR: ${
                                                        xmlFlightVerified.arrival.actual
                                                            ? xmlFlightVerified.arrival.actual.time
                                                            : xmlFlightVerified.arrival.updated
                                                                ? xmlFlightVerified.arrival.updated.time
                                                                : xmlFlightVerified.arrival.scheduled.time}`
                                                        }
                                                    </label>
                                                </div>
                                            )}

                                            {(xmlFlightVerified.departure.scheduled) && (
                                                <div className="w-100 my-1 d-flex flex-column align-items-center p-2 border border-dark">
                                                    <label> SCHEDULED </label>
                                                    <label>
                                                        DEP: {xmlFlightVerified.departure.scheduled.time} ARR: {xmlFlightVerified.arrival.scheduled.time}
                                                    </label>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="mb-4 border rounded border-dark p-3 d-flex">
                            <div className="flex-1 d-flex flex-column align-items-start">
                                <label>
                                    {formValues
                                        ? typeof formValues.airline == 'object'
                                            ? formValues.airline.code
                                            : formValues.airline
                                        : ''
                                    } {formValues ? formValues.flight : ''}
                                </label>
                                <label> NOT FOUND </label>
                            </div>
                            <div className="flex-1 d-flex flex-column align-items-end">
                                <label className="bold text-uppercase">{formatFlightStatus()}</label>
                                <div className="w-100 d-flex flex-column align-items-center text-primary p-2" style={{ background: '#ebebeb' }}>
                                    <label className="my-2">NA</label>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="d-flex justify-content-end">
                        {Object.keys(xmlFlightVerified).length === 0 ||
                        xmlFlightVerified.noInfo ? (
                            <button
                                type="button"
                                className="btn btn-link bg-white border border-dark text-decoration-none text-dark"
                                onClick={() => {
                                    useOriginalVerifyFlight();
                                    setModalVerifyFlight(false);
                                }}
                            >
                                CANCEL
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-link bg-white border border-dark text-decoration-none text-dark"
                                onClick={() => {
                                    originalVerifyFlight();
                                    setModalVerifyFlight(false);
                                }}
                            >
                                {xmlFlightVerified.arrival.updated ||
                                xmlFlightVerified.arrival.actual ? (
                                    <React.Fragment>
                                        <small className="d-none d-md-inline">
                                            USE SCHEDULED TIME
                                        </small>
                                        <small className="d-md-none">
                                            SCHEDULED
                                        </small>
                                    </React.Fragment>
                                ) : (
                                    <small>SELECT</small>
                                )}
                            </button>
                        )}

                        {Object.keys(xmlFlightVerified).length > 0 &&
                            !xmlFlightVerified.noInfo &&
                            (xmlFlightVerified.departure.updated ||
                                xmlFlightVerified.departure.actual) && (
                                <button
                                    type="button"
                                    className="btn btn-dark ml-2"
                                    onClick={() => {
                                        verifyFlight();
                                        setModalVerifyFlight(false);
                                    }}
                                >
                                    <small className="d-none d-md-inline">
                                        USE UPDATED TIME
                                    </small>
                                    <small className="d-md-none">UPDATED</small>
                                </button>
                            )}

                        {Object.keys(xmlFlightVerified).length > 0 &&
                        !xmlFlightVerified.noInfo &&
                        (xmlFlightVerified.departure.updated ||
                            xmlFlightVerified.departure.actual) ? (
                            <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    setModalVerifyFlight(false);
                                }}
                            >
                                <small>CANCEL</small>
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </LoadMask>
        </div>
    ) : null;
};

const mstp = (state) => ({
    ...state.liveryEdition,
    formValues: state.form.createLivery && state.form.createLivery.values,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(NoModalVerify);
