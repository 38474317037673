import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderSearchSelect, renderField, renderLabeledField2, renderTextArea, renderPlacesAutocomplete } from '../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import app from '../../../../libs/apiClient';
import { actions } from "../../../../redux/modules/priceCheck";
import { loadMainVehicles, loadFleetDrivers } from '../../../../redux/modules/expenseTickets';
import renderDatePicker from '../../Utils/renderField/renderDatePicker'

const ServiceRepairForm = (props) => {
  const { handleSubmit, formValues, from ={}, to={}, setDetails } = props;

  const loadAirports = (search) => {
    if (!search) return [];

    const [code] = search.trim().match(/^\w{2,3}(\s+)/g) || [search];
    const [name] = search.trim().match(/(\s+)(?!^\w{2,3}).+$/g) || [search];

    return app
        .service("/api/airport")
        .find({
            query: {
                $paginate: false,
                $or: [
                    {
                        code: {
                            $regex: _.escapeRegExp(code.trim()),
                            $options: "i",
                        },
                    },
                    {
                        name: {
                            $regex: _.escapeRegExp(name.trim()),
                            $options: "i",
                        },
                    },
                ],
                $limit: 5,
            },
        })
        .then((result) => {
            if (result.length > 0) {
                for (let i = 0; i < result.length; i += 1) {
                    Object.assign(result[i], {
                        label: `${result[i].name} (${result[i].code})`,
                        formatted_address: `${result[i].name} (${result[i].code})`,
                        postal_code: result[i].zip,
                        street_number: result[i].street,
                        types: ["airport"],
                        fromDB: true,
                    });
                    delete result[i].street;
                    delete result[i].zip;
                }
            }
            return result;
        })
        .catch(() => {
            return [];
        });
};

  return (
    <form onSubmit={handleSubmit}>
      <div className="container p-4">
      <div className="row mb-3">
          <div className="col-md-6">
            <strong>Start Date/Time</strong>
            <Field
              name="startDate"
              component={renderDatePicker}
              placeholder="12/31/2024 12:00 PM"
              numberOfMonths={1}
              showButton={false}
              className="p-0"
            />
          </div>
          
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <strong>Driver</strong>
            <Field
              name="driver"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadFleetDrivers}
              allKey
            />
          </div>
          <div className="col-md-6">
            <strong>Vehicle</strong>
            <Field
              name="vehicle"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadMainVehicles}
              allKey
            />
          </div>
        </div>
        <label htmlFor="bal2base">
                        <Field
                            id="bal2base"
                            component={"input"}
                            type="checkbox"
                            name={'bal2base'}
                        />
                        &nbsp;Driver brought the van from Baltimore to Base
                    </label>

        <div className="row mb-3">
              <div className='col-12'>
              <strong>Start Location</strong>
              <Field
                        name="startLocation"
                        component={renderPlacesAutocomplete}
                        pl="from"
                        lat={from && from.lat}
                        lng={from && from.lng}
                        additionalAddressInfo={['street_number', 'route']}
                        placeholder="Airport, hotel, address..."
                        aditionalAutocomplete={loadAirports}
                        setDetails={setDetails}
                    />
              </div>
        </div>
        <div className="row mb-3">
              <div className='col-12'>
              <strong>End Location</strong>
              <Field
                        name="endLocation"
                        component={renderPlacesAutocomplete}
                        pl="to"
                        lat={to && to.lat}
                        lng={to && to.lng}
                        additionalAddressInfo={['street_number', 'route']}
                        placeholder="Airport, hotel, address..."
                        aditionalAutocomplete={loadAirports}
                        setDetails={setDetails}
                    />
              </div>
        </div>
        <div className="row mb-3">
              <div className='col-12'>
              <strong>Notes</strong>
              <Field
                        name="notes"
                        component={renderTextArea}
                        rows={4}
                        placeholder="Notes..."
                        className="w-100"
                    />
              </div>
        </div>

        <div className="text-center mt-2">
          <button type="submit" className="btn btn-primary btn-lg w-100">Save</button>
        </div>
      </div>
    </form>
  );
};


const mapStateToProps = (state) => ({
  formValues: (state.form.serviceRepairForm && state.form.serviceRepairForm.values) ? state.form.serviceRepairForm.values : {}
});

const mdtp = {
    ...actions,
}

export default connect(mapStateToProps, mdtp)(reduxForm({
  form: 'serviceRepairForm',
  validate:(data)=>{
    const errors = {}
    if (!data.startDate) errors.startDate = "Required Field";
    if (!data.driver) errors.driver = "Required Field";
    if (!data.vehicle) errors.vehicle = "Required Field";
    if (!data.startLocation) errors.startLocation = "Required Field";
    if (!data.endLocation) errors.endLocation = "Required Field";

    
    return errors
}
})(ServiceRepairForm));
