import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validators,
    validatorFromFunction,
    combine,
} from "validate-redux-form";
import moment from "moment";

import { renderField, renderSwitch } from "Utils/renderField/renderField";
import renderDatePicker from "Utils/renderField/renderDatePicker";
import { future } from "../../../../utility/form-validations";

const Form = (props) => {
    const { handleSubmit, cancel } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Promo Code</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="code">Code</label>
                            <Field
                                name="code"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="discount">Discount</label>
                            <Field
                                name="discount"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="startDate">Start Date</label>
                            <Field
                                name="startDate"
                                component={renderDatePicker}
                                placeholder="12/31/2024 12:00 PM"
                                numberOfMonths={1}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="expDate">Expiration Date</label>
                            <Field
                                name="expDate"
                                component={renderDatePicker}
                                placeholder="12/31/2024 12:00 PM"
                                numberOfMonths={1}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-column">
                            <label htmlFor="expDate">Promo Code Type</label>
                            <div className="flex-1 d-flex">
                                <div className="radio c-radio c-radio-nofont d-flex">
                                    <label className="negro font-weight-normal">
                                        <Field
                                            name="type"
                                            className="form-control p-0"
                                            component={"input"}
                                            type="radio"
                                            value="trip"
                                        />
                                        <span />
                                        Trip
                                    </label>
                                </div>
                                <div className="radio c-radio c-radio-nofont d-flex pl-5">
                                    <label className="negro font-weight-normal">
                                        <Field
                                            name="type"
                                            className="form-control p-0"
                                            component={"input"}
                                            type="radio"
                                            value="tour"
                                        />
                                        <span />
                                        Tour
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 d-flex flex-column">
                            <label className="negro font-weight-normal px-1">
                                Allow with special-rate period?
                            </label>
                            <Field
                                name="overrideSpecialRate"
                                className="p-0"
                                component={renderSwitch}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 d-flex flex-column">
                            <label className="negro font-weight-normal px-1">
                                Allow with point-to-point specials?
                            </label>
                            <Field
                                name="overridePointToPoint"
                                className="p-0"
                                component={renderSwitch}
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button type="button"
                                    className="btn btn-secondary m-1"
                                    onClick={cancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "promoCodeLocationsForm",
    validate: (data) => {
        const porcentaje = validatorFromFunction(
            (value) => 0 <= value && value <= 100
        );

        return validate(data, {
            code: validators.exists()("Required field"),
            discount: combine(
                validators.exists()("Required field"),
                porcentaje()("Please enter a number between 1 and 100")
            ),
            startDate: validators.exists()("Required field"),
            expDate: combine(
                validators.exists()("Required field"),
                future(data.startDate)()(
                    "The exparation date must be after start date"
                )
            ),
            type: validators.exists()("Select one"),
        });
    },
})(Form);
