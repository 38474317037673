import { handleActions } from "redux-actions";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { goBack } from "react-router-redux";
import swal from "sweetalert2";
import _ from "lodash";
import app from "../../libs/apiClient";
import { vehicles, stateOptions } from "../../common/utility/constants";
import moment from 'moment';
import { DateObject } from "react-multi-date-picker";
import XLSX from "xlsx";
import { errorHandler } from '../../common/utility/constants';
import { actions as breadcrumbActions } from "./breadcrumb";

const LOADING = "LOC_LOADING";
const SET_DATA = "LOC_SET_DATA";
const PAGE = "LOC_PAGE";
const SET_ZIP_FILTER = "SET_ZIP_FILTER";
const SET_EXCLUDE_ZIP_FILTER = "SET_EXCLUDE_ZIP_FILTER";
const SET_UPDATE_DATA = "LOC_SET_UPDATE_DATA";
const SEARCH_LOCATION = "SEARCH_LOCATION";
const SET_VEHICLE_EDIT = "SET_VEHICLE_EDIT";
const VEHICLE_LOCATION_LOADER = "VEHICLE_LOCATION_LOADER";
const MODAL_VEHICLE_LOCATION = "MODAL_VEHICLE_LOCATION";
const MODAL_SPECIAL_RATES = "MODAL_SPECIAL_RATES";
const SPECIAL_RATES_LOADER = "SPECIAL_RATES_LOADER";
const UPDATE_DATA_SPECIAL_RATE = "UPDATE_DATA_SPECIAL_RATE";
const DATA_CREATE_SPECIAL_RATE = "DATA_CREATE_SPECIAL_RATE";
const SEARCH_SPECIAL_RATE = "SEARCH_SPECIAL_RATE";
const MODAL_REPORT = "MODAL_REPORT";
const LOCATIONS_HEADER = "LOCATIONS_HEADER";
const LOADER_MODAL_REPORT = "LOADER_MODAL_REPORT";

const service = app.service("/api/location/");
const vehicleService = app.service("/api/vehicle/");

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setData = (data) => ({
    type: SET_DATA,
    data,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setUpdateData = (updateData) => ({
    type: SET_UPDATE_DATA,
    updateData,
});

export const setZipFilter = (zips) => ({
    type: SET_ZIP_FILTER,
    zipFilter: zips,
});

export const setExcludeZipFilter = (excludeZipFilter) => ({
    type: SET_EXCLUDE_ZIP_FILTER,
    excludeZipFilter,
});


// ------------------------------------
// Actions
// ------------------------------------

const loading = (loading = true) => (dispatch, getStore) => {
    const loader = getStore().locations.loading;
    if (loading) {
        dispatch({ type: LOADING, loading: loader + 1 });
    } else {
        dispatch({ type: LOADING, loading: loader - 1 });
    }
};

export const create = () => (dispatch, getState) => {
    // get form data
    const formData = getState().form.location.values;
    const { dataToCreateSpecialRate } = getState().locations;

    dispatch(loading());

    if(dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates)
        formData.specialRates = dataToCreateSpecialRate.specialRates

    service.create(formData).then((data) => {
        // go back
        dispatch(goBack());
    })
    .catch((e) => {
        dispatch(errorHandler(e));
    })
    .finally(() => {
        dispatch(loading(false));
    });
};

export const update = () => async (dispatch, getState) => {
    // get form data
    const formData = getState().form.location.values;

    dispatch(loading());

    service.patch(formData._id, formData).then(() => {
        // go back
        dispatch(goBack());
    }).catch((e) => {
        dispatch(errorHandler(e));
    }).finally(() => {
        dispatch(loading(false));
    });
};

export const setAddress = (address, lat, lng) => (dispatch, getState) => {
    const formData = getState().form.location.values;
    formData.address = address;
    formData.lat = lat;
    formData.lng = lng;
    dispatch(initializeForm("location", formData));
};

export const setCity = (city) => (dispatch, getState) => {
    const formData = getState().form.location.values;
    formData.city = city;
    dispatch(initializeForm("location", formData));
};

export const setState = (state) => (dispatch, getState) => {
    const formData = getState().form.location.values;
    formData.state = state;
    dispatch(initializeForm("location", formData));
};

export const find = (page = 1) => (dispatch, getStore) => {
    // TODO: actual find query
    dispatch(loading());
    const query = { query: { $skip: (page - 1) * 10 } };
    const { search } = getStore().locations;
    if(search){
        const stateArray = stateOptions.reduce( (array, current) => {
            if(current.label.toLocaleLowerCase().indexOf(`${search.toLocaleLowerCase()}`) !== -1)
                array.push(current.value);
            return array;
        }, [] );
        query.query.$or = [
            { city: { $regex: _.escapeRegExp(search), $options: 'i'} },
            { state: { $in: stateArray } },
            { cityCode: { $regex: _.escapeRegExp(search), $options: 'i'} },
        ]
    }

    service.find(query)
        .then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        })
        .catch((err) => {
            dispatch(errorHandler(err));
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const destroy = (id) => (dispatch) => {
    dispatch(loading());
    service.remove(id)
        .then(() => {
            // reload
            dispatch(find());
            dispatch(loading(false));
        })
        .catch((e) => {
            dispatch(loading(false));
            dispatch(errorHandler(e));
        });
};

export const load2Update = (id) => (dispatch) => {
    dispatch(loading());
    service.get(id, { query: {
        $select: ['address','city','state','lat','lng','cityCode']
    }}).then( async (response) => {

        dispatch(setUpdateData(response));
        dispatch(initializeForm("location", response));
    })
    .catch((e) => {
        dispatch(errorHandler(e));
    })
    .finally(() => {
        dispatch(loading(false));
    });
};

export const filterZipCodes = () => (dispatch, getState) => {
    const { zipFilter, zipCodes } = getState().form.location.values || {};
    let { zipFilter: zipArray } = getState().locations;

    if (!zipFilter) {
        dispatch(setZipFilter([]));
    } else if (!zipArray.length && zipFilter && zipFilter.length === 1) {
        dispatch(setZipFilter(zipCodes));
    }

    if (zipFilter) {
        zipArray = getState().locations.zipFilter;
        const filteredZipCodes = zipArray.filter(({ zip }) =>
            zip.includes(zipFilter)
        );

        dispatch(change("location", "zipCodes", filteredZipCodes));
    } else dispatch(change("location", "zipCodes", zipArray));
};

export const filterExcludeZipCodes = () => (dispatch, getStore) => {
    const { excludeZipFilter, excludeZipCodes } = getStore().form.location.values || {};
    let { excludeZipFilter: zipArray } = getStore().locations;

    if (!excludeZipFilter) {
        dispatch(setExcludeZipFilter([]));
    } else if (!zipArray.length && excludeZipFilter && excludeZipFilter.length === 1) {
        dispatch(setExcludeZipFilter(excludeZipCodes));
    }

    if (excludeZipFilter) {
        zipArray = getStore().locations.excludeZipFilter;
        const filteredZipCodes = zipArray.filter(({ zip }) =>
            zip.includes(excludeZipFilter)
        );

        dispatch(change("location", "excludeZipCodes", filteredZipCodes));
    } else dispatch(change("location", "excludeZipCodes", zipArray));
}

export const importXLSX = (file) => async (dispatch) => {
    dispatch(loading());
    try {
        await service.create({ file });
        dispatch(find());
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
};

export const setSearch = () => (dispatch, getStore) => {
    const store = getStore().form.locationList;
    const search = store.values && store.values.searchLocation;
    dispatch({type: SEARCH_LOCATION, search});
    dispatch(find())
}

const openEditVehicle = (index) => async (dispatch, getStore) => {
    dispatch(openCloseModal(true));
    dispatch(setModalLoading(true));

    const { values } = getStore().form.location;
    const dataIndex = values.vehicleBaseRates[index];
    let dataDefault;
    const { updateData } = getStore().locations;
    // If the data is for update
    if(updateData && Object.keys(updateData).length > 0)
        dataDefault = updateData.vehicleBaseRates[index];

    let vehicleEdit;
    if(dataDefault){ // FOR FORM UPDATE
        if(dataIndex.vehicle === dataDefault.vehicle && dataDefault.vehicleLocation){
            vehicleEdit = await app.service('/api/vehicleLocation').get(dataDefault.vehicleLocation);
        } else {
            vehicleEdit = await vehicleService.get(dataIndex.vehicle);
            if(vehicleEdit.features)
                delete vehicleEdit.features;
        }
    } else { // FOR FORM CREATE
        if(dataIndex.vehicleLocation){
            vehicleEdit = await app.service('/api/vehicleLocation').get(dataIndex.vehicleLocation);
            if(vehicleEdit.vehicleDefault !== dataIndex.vehicle){
                vehicleEdit = await app.service('/api/vehicle').get(dataIndex.vehicle)
                if(vehicleEdit.features)
                    delete vehicleEdit.features;
            }
        } else {
            vehicleEdit = await vehicleService.get(dataIndex.vehicle);
            if(vehicleEdit.features)
                delete vehicleEdit.features;
        }
    }
    vehicleEdit.baseRateIndex = index;
    dispatch(setVehicleEdit(vehicleEdit))
    dispatch(initializeForm('vehicleLocationForm', vehicleEdit));

    dispatch(setModalLoading(false));
}

const updateVehicleLocation = (formData) => async (dispatch, getStore) => {
    const data = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    }

    const { updateData } = getStore().locations;
    const { values } = getStore().form.location;
    const formVehicleRateData = values.vehicleBaseRates[data.baseRateIndex];

    try {
        dispatch(setModalLoading(true));
        let idVehicleLocation;
        if( updateData.vehicleBaseRates[data.baseRateIndex] &&
            updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation)
        {
            data.vehicleDefault = formVehicleRateData.vehicle;
            idVehicleLocation = updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation
            await app.service('/api/vehicleLocation').patch(idVehicleLocation, data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
        } else {
            data.vehicleDefault = data._id;
            delete data._id;
            const response = await app.service('/api/vehicleLocation').create(data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
            idVehicleLocation = response._id;
        }
        // If exists the index in the updateData
        if(updateData.vehicleBaseRates[data.baseRateIndex]){
            updateData.vehicleBaseRates[data.baseRateIndex].vehicle = formVehicleRateData.vehicle;
            updateData.vehicleBaseRates[data.baseRateIndex].rate = formVehicleRateData.rate;
            updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation = idVehicleLocation;
        } else {
            updateData.vehicleBaseRates[data.baseRateIndex] = {
                vehicle: formVehicleRateData.vehicle,
                rate: formVehicleRateData.rate,
                vehicleLocation: idVehicleLocation,
            }
        }
        await service.patch(updateData._id, {
            vehicleBaseRates: updateData.vehicleBaseRates
        })
        dispatch(setUpdateData(updateData));
        dispatch(closeEditVehicle());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setModalLoading(false));
    }
}

// Create a new vehicle location for the location
const createVehicleLocation = (formData) => async (dispatch, getStore) => {
    const data = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    }

    const { values } = getStore().form.location;
    const formVehicleRateData = values.vehicleBaseRates[data.baseRateIndex];

    try {
        dispatch(setModalLoading(true));
        let idVehicleLocation;
        if(formVehicleRateData.vehicleLocation){
            data.vehicleDefault = formVehicleRateData.vehicle;
            idVehicleLocation = formVehicleRateData.vehicleLocation
            await app.service('/api/vehicleLocation').patch(idVehicleLocation, data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
        } else {
            data.vehicleDefault = data._id;
            delete data._id;
            const response = await app.service('/api/vehicleLocation').create(data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
            idVehicleLocation = response._id;
        }
        formVehicleRateData.vehicle = formVehicleRateData.vehicle;
        formVehicleRateData.vehicleLocation = idVehicleLocation;
        dispatch(change('location','vehicleBaseRates', values.vehicleBaseRates))
        dispatch(closeEditVehicle());
    } catch (e){
        dispatch(errorHandler(e));
    } finally {
        dispatch(setModalLoading(false));
    }
}

// Remove the vehicle location when the field in the form is removed
const removeBaseRate = (index, removeField) => async (dispatch, getStore) => {

    const { values: oldValues } = getStore().form.location;
    const { updateData } = getStore().locations;
    const idVehicleLocation = oldValues && oldValues.vehicleBaseRates &&
        oldValues.vehicleBaseRates[index] && oldValues.vehicleBaseRates[index].vehicleLocation;

    removeField(index);

    if(updateData && Object.keys(updateData).length > 0){
        updateData.vehicleBaseRates.splice(index, 1);
        dispatch(setUpdateData(updateData));
    }

    if(idVehicleLocation){
        await app.service('/api/vehicleLocation').remove(idVehicleLocation);
        if(oldValues._id){
            const { values: newValues } = getStore().form.location;
            await service.patch(oldValues._id, {
                vehicleBaseRates: newValues.vehicleBaseRates
            });
        }
    }
}

// If there was a vehicle in the base rate of the location with vehicleLocation param
// We need to verify if is the same in the db, if not, we need to update the data in the db
const verifyBaseRatesCreate = () => async (dispatch, getStore) => {

    const { values, submitSucceeded } = getStore().form.location;
    const arrayWithVehicleLocation = values && values.vehicleBaseRates
        ? values.vehicleBaseRates.filter( item => item.vehicleLocation)
        : [];

    if(!submitSucceeded && arrayWithVehicleLocation.length > 0){
        for(let index = 0; index < arrayWithVehicleLocation.length ; index ++){
            await app.service('/api/vehicleLocation').remove(arrayWithVehicleLocation[index].vehicleLocation);
        }
    }
}

const restoreDefault = () => async (dispatch, getStore) => {

    dispatch(setModalLoading(true));
    const { vehicleEdit, updateData } = getStore().locations;
    const { values } = getStore().form.location;
    let idVehicleLocation;
    try{
        idVehicleLocation = values.vehicleBaseRates[vehicleEdit.baseRateIndex].vehicleLocation;
    } catch (e){}

    if(idVehicleLocation){
        await app.service('/api/vehicleLocation').remove(idVehicleLocation);
        if(values._id){
            delete values.vehicleBaseRates[vehicleEdit.baseRateIndex].vehicleLocation;
            await service.patch(values._id, {
                vehicleBaseRates: values.vehicleBaseRates
            });
            dispatch(change('location', 'vehicleBaseRates', values.vehicleBaseRates));
        }
        if(updateData && Object.keys(updateData).length > 0){
            delete updateData.vehicleBaseRates[vehicleEdit.baseRateIndex].vehicleLocation;
            dispatch(setUpdateData(updateData));
        }
        dispatch(setModalLoading(false));
        dispatch(openEditVehicle(vehicleEdit.baseRateIndex));
    } else {
        dispatch(setModalLoading(false));
    }
}

/* ****************************************************************************************** */
/*                                 Espcial Rates                                              */
/* ****************************************************************************************** */

const changeRadioChecks = (dates) => (dispatch, getStore) => {

    const { values } = getStore().form.especialRatesForm;

    if( values ){

        const { calendar, recurring } = values;

        let minOption;
        if(calendar){
            let arrayDates = [];
            for(let index = 0; index < calendar.length; index++){
                arrayDates.push(moment(`${calendar[index].month.number}/${calendar[index].day}/${calendar[index].year}`));
            }
            if(arrayDates.length > 0){
                let maxDate = arrayDates.reduce((max, current) => current.isAfter(max) ? current : max, arrayDates[0]);
                let minDate = arrayDates.reduce((min, current) => current.isBefore(min) ? current : min, arrayDates[0]);
                // let diference = Math.abs(minDate.diff(maxDate,'days'));
                if(maxDate.month() !== minDate.month())
                    minOption = 'yearly';
                else if(minDate.week() !== maxDate.week())
                    minOption = 'monthly';
                else
                    minOption = 'weekly';
            }
        }

        if( (minOption === 'monthly' && recurring === 'weekly') ||
            (minOption === 'yearly' && (recurring === 'weekly' || recurring === 'monthly'))
        ){
            dispatch(change('especialRatesForm', 'recurring', minOption));
        }
    }
}

const loadEditVehicleSpecialRate = (index) => async (dispatch, getStore) => {
    dispatch(setModalLoading(true));

    const { values } = getStore().form.especialRatesForm;
    const { values: locationValues } = getStore().form.location;
    const dataIndex = values.specialRates[index];
    const { updateData: dataLocations } = getStore().locations;
    const { updateDataEspecialRate: updateData } = getStore().locations;

    // Find in data location if there is a row with the vehicle _id and vehicleLocation
    if(dataLocations && dataLocations._id){
        let found = dataLocations.vehicleBaseRates.find(item => item.vehicle === dataIndex.vehicle);
        if(found)
            dataIndex.vehicleLocation = found.vehicleLocation;
    }

    let dataDefault;
    // If the data is for update
    if(updateData && Object.keys(updateData).length > 0)
        dataDefault = updateData.specialRates[index];

    let vehicleEdit;
    if(dataIndex.vehicleSpecialRate){
        if(typeof dataIndex.vehicleSpecialRate === 'object'){ // FOR FORM UPDATE
            if(dataIndex.vehicleSpecialRate.vehicleDefault !== dataIndex.vehicle){
                vehicleEdit = dataIndex.vehicleLocation
                    ? await app.service('/api/vehicleLocation').get(dataIndex.vehicleLocation)
                    : await vehicleService.get(dataIndex.vehicle);
                delete vehicleEdit._id;
            } else {
                vehicleEdit = dataIndex.vehicleSpecialRate;
            }
        } else {
            vehicleEdit = await app.service('/api/vehicleEspecialRate').get(dataIndex.vehicleSpecialRate);
        }
    } else {
        if(dataDefault){
            if(dataIndex.vehicle === dataDefault.vehicle && dataDefault.vehicleLocation){
                vehicleEdit = await app.service('/api/vehicleLocation').get(dataDefault.vehicleLocation);
            } else {
                vehicleEdit = await vehicleService.get(dataIndex.vehicle);
                vehicleEdit.vehicleDefault = vehicleEdit._id;
                if(vehicleEdit.features)
                    delete vehicleEdit.features;
            }
        } else { // FOR FORM CREATE
            if(dataIndex.vehicleLocation){
                vehicleEdit = await app.service('/api/vehicleLocation').get(dataIndex.vehicleLocation);
                if(vehicleEdit.vehicleDefault !== dataIndex.vehicle){
                    vehicleEdit = await app.service('/api/vehicle').get(dataIndex.vehicle)
                    vehicleEdit.vehicleDefault = vehicleEdit._id;
                    if(vehicleEdit.features)
                        delete vehicleEdit.features;
                }
            } else {
                vehicleEdit = await vehicleService.get(dataIndex.vehicle);
                vehicleEdit.vehicleDefault = vehicleEdit._id;
                if(vehicleEdit.features)
                    delete vehicleEdit.features;
            }
        }
        delete vehicleEdit._id;
    }
    vehicleEdit.baseRateIndex = index;
    dispatch(setVehicleEdit(vehicleEdit))
    dispatch(initializeForm('vehicleLocationForm', vehicleEdit));

    dispatch(setModalLoading(false));
}

const updateSpecialRateVehicle = (data, index) => (dispatch, getStore) => {
    const { values } = getStore().form.especialRatesForm;

    if(values){
        let { specialRates } = values;
        specialRates[index].vehicleSpecialRate = data;
        specialRates[index].vehicleInfo = data;
        dispatch(change('especialRatesForm', 'specialRates', specialRates));
    }
}

const submitEspecialRate = (data, closeModal = () => {}) => async (dispatch, getStore) => {
    dispatch(setSpecialRatesLoader(true));
    let { updateData } = getStore().locations;
    let oldSpecialRate = data.specialRateIndex >= 0 && Object.assign({},updateData.specialRates[data.specialRateIndex]);
    try {

        const dates = []
        for(let index = 0; index < data.calendar.length; index++){
            let obj = {
                day: data.calendar[index].day,
                month: data.calendar[index].month.number,
                dayName: data.calendar[index].weekDay.name,
                year: data.calendar[index].year
            }
            dates.push(obj);
        }

        const calendar = { type: data.type, dates };

        if(data.schedule){
            calendar.recurring = data.recurring;
            if(!data.indefinite) // Indefinite is false or not exist
                calendar.repeatTimes = data.repeatTimes;
            else
                calendar.repeatTimes = 0;
        }

        if(data.addTime)
            calendar.time = {
                startTime: `${data.startTime.hour}:${data.startTime.minutes}`,
                endTime: `${data.endTime.hour}:${data.endTime.minutes}`,
            }

        const finalData = { code: data.code, calendar, toVerify: true };

        if(updateData && !updateData.specialRates)
            updateData.specialRates = [];

        if(updateData && updateData._id && updateData.specialRates && updateData.specialRates.length > 0){
            for(let index = 0; index < updateData.specialRates.length; index++){
                if(!updateData.specialRates[index].calendar.dates){
                    let dates = []
                    for(let indexC = 0; indexC < updateData.specialRates[index].calendar.length; indexC++){
                        let obj = {
                            day: updateData.specialRates[index].calendar[indexC].day,
                            month: updateData.specialRates[index].calendar[indexC].month.number,
                            dayName: updateData.specialRates[index].calendar[indexC].weekDay.name,
                            year: updateData.specialRates[index].calendar[indexC].year
                        }
                        dates.push(obj);
                    }
                    let obj = Object.assign({},{
                        code: updateData.specialRates[index].code,
                        calendar: {
                            dates,
                            type: updateData.specialRates[index].type
                                ? updateData.specialRates[index].type
                                : updateData.specialRates[index].calendar.type
                                    ? updateData.specialRates[index].calendar.type : undefined,
                            recurring: updateData.specialRates[index].calendar.recurring
                                ? updateData.specialRates[index].calendar.recurring
                                : updateData.specialRates[index].recurring
                                    ? updateData.specialRates[index].recurring : undefined,
                            repeatTimes: updateData.specialRates[index].calendar.repeatTimes
                                ? updateData.specialRates[index].calendar.repeatTimes
                                : updateData.specialRates[index].repeatTimes
                                    ? updateData.specialRates[index].repeatTimes : undefined,
                            time: updateData.specialRates[index].calendar.time
                                ? updateData.specialRates[index].calendar.time
                                : updateData.specialRates[index].time
                                    ? updateData.specialRates[index].time : undefined,
                        },
                        vehicles: updateData.specialRates[index].vehicles,
                    });
                    if(updateData.specialRates[index]._id)
                        obj._id = updateData.specialRates[index]._id;
                    updateData.specialRates[index] = obj;
                }
            }
        }

        if(data._id){ // FOR UPDATE
            const vehicles = [];
            for(let index = 0; index < data.specialRates.length; index++){
                let vehicle;
                if(data.specialRates[index]._id){ // FOR UPDATE VEHICLE SPECIAL RATE
                    if(typeof data.specialRates[index].vehicleSpecialRate === 'object'){
                        let dataVehicle = {
                            ...data.specialRates[index].vehicleSpecialRate,
                            picture: {
                                buffer: data.specialRates[index].vehicleSpecialRate.picture,
                                mimeType: data.specialRates[index].vehicleSpecialRate.picture && data.specialRates[index].vehicleSpecialRate.picture.type,
                            }
                        }
                        if(data.specialRates[index].vehicleSpecialRate._id)
                            vehicle = await app.service('/api/vehicleEspecialRate').patch(data.specialRates[index].vehicleSpecialRate._id, dataVehicle,
                                { headers: { "content-type": "multipart/form-data" } });
                        else
                            vehicle = await app.service('/api/vehicleEspecialRate').create(dataVehicle,
                                { headers: { "content-type": "multipart/form-data" } });
                    } else vehicle = { _id: data.specialRates[index].vehicleSpecialRate }
                } else { // FOR CREATE VEHICLE SPECIAL RATE
                    let dataTemp;
                    if(data.specialRates[index].vehicleSpecialRate){
                        dataTemp = {
                            ...data.specialRates[index].vehicleSpecialRate,
                            picture: {
                                buffer: data.specialRates[index].vehicleSpecialRate.picture,
                                mimeType: data.specialRates[index].vehicleSpecialRate.picture && data.specialRates[index].vehicleSpecialRate.picture.type,
                            }
                        }
                    } else {
                        dataTemp = data.specialRates[index].vehicleLocation
                            ? await app.service('/api/vehicleLocation').get(data.specialRates[index].vehicleLocation)
                            : await vehicleService.get(data.specialRates[index].vehicle);
                    }
                    if(!dataTemp.vehicleDefault) dataTemp.vehicleDefault = dataTemp._id;
                    delete dataTemp._id;
                    vehicle = await app.service('/api/vehicleEspecialRate').create(dataTemp,
                        { headers: { "content-type": "multipart/form-data", } });
                }
                vehicles.push({
                    rate: data.specialRates[index].rate,
                    vehicle: data.specialRates[index].vehicle,
                    vehicleLocation: data.specialRates[index].vehicleLocation,
                    vehicleSpecialRate: vehicle._id,
                })
            }

            //Remove specialRates vehicles if it were removed on the view form
            let forDelete = updateData.specialRates[data.specialRateIndex].vehicles.filter(item =>
                vehicles.find(itemVehicle => typeof item.vehicleSpecialRate === 'object'
                    ? itemVehicle.vehicleSpecialRate === item.vehicleSpecialRate._id
                    : itemVehicle.vehicleSpecialRate === item.vehicleSpecialRate ) === undefined);
            for(let index = 0; index < forDelete.length; index++)
                await app.service('/api/vehicleEspecialRate').remove(forDelete[index].vehicleSpecialRate);

            // save the final data
            finalData.vehicles = vehicles;
            updateData.specialRates[data.specialRateIndex] = finalData;

        } else { // FOR CREATE
            const vehicles = [];
            for(let index = 0; index < data.specialRates.length; index++){
                let dataTemp;
                if(data.specialRates[index].vehicleSpecialRate){
                    if(typeof data.specialRates[index].vehicleSpecialRate === 'object'){
                        dataTemp = {
                            ...data.specialRates[index].vehicleSpecialRate,
                            picture: {
                                buffer: data.specialRates[index].vehicleSpecialRate.picture,
                                mimeType: data.specialRates[index].vehicleSpecialRate.picture && data.specialRates[index].vehicleSpecialRate.picture.type,
                            }
                        }
                    }
                } else {
                    dataTemp = data.specialRates[index].vehicleLocation
                      ? await app.service('/api/vehicleLocation').get(data.specialRates[index].vehicleLocation)
                      : await app.service('/api/vehicle').get(data.specialRates[index].vehicle)
                    if(!dataTemp.vehicleDefault)
                        dataTemp.vehicleDefault = dataTemp._id;
                    delete dataTemp._id;
                }
                let vehicle;
                if(dataTemp){
                    vehicle = await app.service('/api/vehicleEspecialRate').create(dataTemp,
                        { headers: { "content-type": "multipart/form-data" } });
                }else if(data.specialRates[index].vehicleSpecialRate){
                    vehicle = { _id: data.specialRates[index].vehicleSpecialRate }
                }
                vehicles.push({
                    rate: data.specialRates[index].rate,
                    vehicle: data.specialRates[index].vehicle,
                    vehicleLocation: data.specialRates[index].vehicleLocation,
                    vehicleSpecialRate: vehicle._id,
                })
            }
            finalData.vehicles = vehicles;
            if(updateData && updateData._id) updateData.specialRates.push(finalData);
        }

        if(updateData && updateData._id){ // FOR UPDATE
            const { values } = getStore().form.location;
            if(values && values.searchSpecialRate && oldSpecialRate){
                let oldUpdateData = await service.get(updateData._id);
                let index = oldUpdateData.specialRates.findIndex( item => item._id === oldSpecialRate._id);
                oldUpdateData.specialRates[index] = Object.assign({}, updateData.specialRates[data.specialRateIndex]);
                await service.patch(oldUpdateData._id, { specialRates: oldUpdateData.specialRates });
            } else {
                updateData = await service.patch(updateData._id, { specialRates: updateData.specialRates });
            }
            if(updateData.specialRates)
                updateData = await populateVehicleInfoSpecialRate(updateData);
            dispatch(setUpdateData(updateData));
        } else { // FOR CREATE
            let { dataToCreateSpecialRate, updateDataEspecialRate } = getStore().locations;
            if(dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates){
                if(updateDataEspecialRate && updateDataEspecialRate.specialRateIndex >= 0){
                    dataToCreateSpecialRate.specialRates[updateDataEspecialRate.specialRateIndex] = finalData;
                } else {
                    dataToCreateSpecialRate.specialRates.push(finalData);
                }
            } else {
                dataToCreateSpecialRate = { specialRates: [finalData] };
            }
            dataToCreateSpecialRate = await populateVehicleInfoSpecialRate(dataToCreateSpecialRate);
            dispatch(setDataToCreate(dataToCreateSpecialRate));
        }
        dispatch(closeSpecialRates());
        closeModal();

    } catch (e) {
        if((e.data && e.data.index)){
            if((updateData && updateData._id) && !data._id){
                updateData.specialRates.splice(e.data.index, 1);
            } else {
                updateData.specialRates[data.specialRateIndex] = oldSpecialRate;
            }
        }
        dispatch(errorHandler(e));
    } finally {
        dispatch(setSpecialRatesLoader(false));
    }
}

const resetInputCalendar = () => (dispatch) => {
    dispatch(change('especialRatesForm','calendar',[]));
}

const setSpecialRatesLoader = (value) => (dispatch, getStore) => {
    dispatch({ type: SPECIAL_RATES_LOADER, specialRatesLoader: value})
}

const closeSpecialRates = () => (dispatch, getStore) => {
    dispatch(destroyForm('especialRatesForm'));
    dispatch({ type: MODAL_SPECIAL_RATES, modalSpecialRates: false });
    dispatch(setSpecialRate({}));
}

const openModalSpecialRates = (index = null) => (dispatch, getStore) => {
    dispatch({ type: MODAL_SPECIAL_RATES, modalSpecialRates: true })
    if(index === null){
        const { values } = getStore().form.location;
        let specialRates = [];
        if(values &&  values.vehicleBaseRates){
            for(let index = 0; index < values.vehicleBaseRates.length; index++){
                specialRates.push(values.vehicleBaseRates[index]);
                delete specialRates[index]._id;
            }
        }
        dispatch(initializeForm('especialRatesForm', {
            calendar: [],
            specialRates,
            recurring: 'weekly',
            type: 'Days',
            repeatTimes: 1,
            isFuture: false
        }));
    } else {
        dispatch(setSpecialRatesLoader(true));
        const { updateData, dataToCreateSpecialRate } = getStore().locations;
        let specialRate = updateData._id
          ? updateData.specialRates[index] // FOR UPDATE
          : dataToCreateSpecialRate.specialRates[index] // FOR CREATE

        specialRate.type = specialRate.calendar.type || specialRate.type;
        specialRate.specialRates = specialRate.vehicles;

        const { calendar: { dates, time, recurring, repeatTimes } } = specialRate;

        if(recurring){
            specialRate.schedule = true;
            specialRate.recurring = recurring;
        }

        if(repeatTimes >= 0){
            if(repeatTimes === 0)
                specialRate.indefinite = true;
            specialRate.repeatTimes = repeatTimes;
        }

        if(time){
            specialRate.addTime = true;
            specialRate.startTime = {
                hour: time.startTime.split(":")[0],
                minutes: time.startTime.split(":")[1]
            }
            specialRate.endTime = {
                hour: time.endTime.split(":")[0],
                minutes: time.endTime.split(":")[1]
            }
        }

        if(dates){
            const newDates = []
            for(let index = 0; index < dates.length; index++)
                newDates.push( new DateObject(`${dates[index].year ? dates[index].year : moment().year()}/${dates[index].month}/${dates[index].day}`) );
            specialRate.calendar = newDates;
        }

        let maxDate = specialRate.calendar.reduce((max, current) => moment(`${current.month.number}/${current.day}/${current.year}`).isAfter(max) ? moment(`${current.month.number}/${current.day}/${current.year}`) : max,
            moment(`${specialRate.calendar[0].month.number}/${specialRate.calendar[0].day}/${specialRate.calendar[0].year}`)
        );
        if(specialRate.repeatTimes > 1)
            maxDate.add(specialRate.repeatTimes, `${specialRate.recurring === 'yearly' ? 'Y' : specialRate.recurring === 'monthly' ? 'M' : 'w'}`)

        specialRate.specialRateIndex = index;
        dispatch(initializeForm('especialRatesForm', {
            ...specialRate,
            recurring: specialRate.recurring ? specialRate.recurring : 'weekly',
            repeatTimes: specialRate.repeatTimes ? specialRate.repeatTimes : 1,
            isFuture: maxDate.isAfter(moment()) || specialRate.repeatTimes === 0 ? true : false
        }));
        dispatch(setSpecialRate(specialRate));
        dispatch(setSpecialRatesLoader(false));
    }
}

const removeSpecialRate = () => async (dispatch, getStore) => {
    const result = await swal.fire({
        type: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: '#D50032',
    })
    if (result.value) {
        dispatch(setSpecialRatesLoader(true));
        let { updateData: temp, dataToCreateSpecialRate, updateDataEspecialRate, } = getStore().locations;
        let updateData = Object.assign({},temp);
        const index = updateDataEspecialRate.specialRateIndex
        let isUpdate = updateData && updateData._id;
        let data = isUpdate ? updateData.specialRates : dataToCreateSpecialRate.specialRates;
        for(let indexRate = 0; indexRate < data[index].vehicles.length; indexRate++){
            let vehicleSpecialRate = data[index].vehicles[indexRate].vehicleSpecialRate;
            if(vehicleSpecialRate)
                await app.service('/api/vehicleEspecialRate').remove(vehicleSpecialRate);
        }
        data.splice(index, 1);
        if(isUpdate){ // FOR UPDATE
            updateData.specialRates = data;
            let response = await service.patch(updateData._id,{ specialRates: updateData.specialRates });
            dispatch(change('location', 'specialRates', response.specialRates));
            response = await populateVehicleInfoSpecialRate(response);
            dispatch(setUpdateData(response));
        } else { // FOR CREATE
            dataToCreateSpecialRate.specialRates = data;
            dispatch(setDataToCreate(dataToCreateSpecialRate));
        }
        dispatch(setSpecialRatesLoader(false));
        dispatch(closeSpecialRates());
        swal.fire(
            'Deleted!',
            'The data has been deleted.',
            'success'
        )
        return true;
    }
    return false;
}

const populateVehicleInfoSpecialRate = async (data) => {
    for(let index = 0; index < data.specialRates.length; index++){
        for(let vehicleIndex = 0; vehicleIndex < data.specialRates[index].vehicles.length; vehicleIndex++){
            data.specialRates[index].vehicles[vehicleIndex].vehicleInfo = data.specialRates[index].vehicles[vehicleIndex].vehicleSpecialRate
                ? await app.service('/api/vehicleEspecialRate').get(data.specialRates[index].vehicles[vehicleIndex].vehicleSpecialRate)
                : data.specialRates[index].vehicles[vehicleIndex].vehicleLocation
                    ? await app.service('/api/vehicleLocation').get(data.specialRates[index].vehicles[vehicleIndex].vehicleLocation)
                    : await vehicleService.get(data.specialRates[index].vehicles[vehicleIndex].vehicle);
        }
    }
    return data;
}

const verifySpecialRates = () => async (dispatch, getStore) => {
    const { dataToCreateSpecialRate } = getStore().locations;
    const { submitSucceeded } = getStore().form.location;

    if(!submitSucceeded && dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates){
        for(let index = 0; index < dataToCreateSpecialRate.specialRates.length; index++){
            for(let indexRate = 0; indexRate < dataToCreateSpecialRate.specialRates[index].vehicles.length; indexRate++){
                let vehicleSpecialRate = dataToCreateSpecialRate.specialRates[index].vehicles[indexRate].vehicleSpecialRate;
                if(vehicleSpecialRate)
                    await app.service('/api/vehicleEspecialRate').remove(vehicleSpecialRate);
            }
        }
    }
    dispatch(setDataToCreate([]));
}

const getVehicles = (search) => (dispatch, getStore) => {
    const { values = {} } = getStore().form.location;
    return new Promise( async (res, rej) => {
        const vehicles = [];
        if(values.vehicleBaseRates){
            try {
                let foundSearch = [];
                if(search){
                    let query = { query: { type: { $regex: _.escapeRegExp(search), $options: 'i' } }}
                    let response = await vehicleService.find(query);
                    if(response && response.total > 0)
                        for(let index = 0; index < response.total; index++)
                            foundSearch.push(response.data[index]);
                }

                for(let index = 0; index < values.vehicleBaseRates.length; index++){
                    if(foundSearch.length > 0){
                        let data = foundSearch.find(item => item._id === values.vehicleBaseRates[index].vehicle)
                        if(data)
                            vehicles.push(data)
                    } else {
                        let vehicle = await vehicleService.get(values.vehicleBaseRates[index].vehicle);
                        vehicles.push(vehicle);
                    }
                }
            } catch (e) {
                rej(e);
            }
        }
        res(vehicles);
    })
}

const seatSearchSpecialRate = (search) => async (dispatch, getStore) => {

    const { updateData, dataToCreateSpecialRate } = getStore().locations;

    const searchFunc = (data, search) => data.specialRates.filter(
        item => { if(
            (item.code.toLowerCase().indexOf(search) !== -1) ||
            (item.vehicles.find( itemVehicle =>
                itemVehicle.vehicleInfo.vehicleCode.toLowerCase().indexOf(search) !== -1
            ) !== undefined) ||
            ((item.calendar.recurring && item.calendar.recurring.toLowerCase().indexOf(search) !== -1) ||
            (item.recurring && item.recurring.toLowerCase().indexOf(search) !== -1))
        )
            return true;
    })

    search = search.toLowerCase();
    dispatch({ type: SEARCH_SPECIAL_RATE, loadingSpecialRates: true });
    if( updateData && updateData._id ){
        let response = await service.get(updateData._id);
        if(response.specialRates)
            response = await populateVehicleInfoSpecialRate(response);
        const final = searchFunc(response, search);
        response.specialRates = final;
        dispatch(setUpdateData(response));
    } else if(dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates){
        const final = searchFunc(dataToCreateSpecialRate, search);
        dataToCreateSpecialRate.specialRates = final;
        dispatch(setDataToCreate(dataToCreateSpecialRate))
    }
    dispatch({ type: SEARCH_SPECIAL_RATE, loadingSpecialRates: false });
}

const generarteReport = () => async (dispatch, getStore) => {
    dispatch({ type: LOADER_MODAL_REPORT, loaderReport: true });
    try {

        const { updateData } = getStore().locations;
        const { values } = getStore().form.reportSpecialRatesForm;
        let response = await service.get(updateData._id);
        if(response.specialRates)
            response = await populateVehicleInfoSpecialRate(response);

        if(values.startDate && values.endDate){
            let start = moment(moment(values.startDate).format('MM/DD/YYYY 00:00:00'));
            let end = moment(moment(values.endDate).format('MM/DD/YYYY 23:59:59'));

            let finalSpecialRates = [];
            for(let index = 0; index < response.specialRates.length; index++ ){

                let { calendar: { repeatTimes = 1, recurring, type, dates, time } } = response.specialRates[index];

                // Type to increase dates in "for" for repeat
                let typeAdd = recurring ? recurring === 'weekly' ? 'w' : recurring === 'monthly' ? 'M' : 'Y' : 'Y'

                let arrDates;
                if(type !== 'Days'){
                    let dateStart = moment(`${dates[0].month}/${dates[0].day}/${dates[0].year}`);
                    let dateEnd = moment(`${dates[1].month}/${dates[1].day}/${dates[1].year}`);
                    let dif = dateEnd.diff(dateStart, 'd');
                    let date = moment(dateStart.format('MM/DD/YYYY'));
                    arrDates = [];
                    for(let count = 0; count <= dif; count++){
                        arrDates.push({
                            day: date.date(),
                            month: date.month() + 1,
                            year: date.year()
                        });
                        date.add(1, 'd')
                    }
                } else {
                    arrDates = dates;
                }

                for(let indexDate = 0; indexDate < arrDates.length; indexDate++){
                    let { day, month, year, dayName } = arrDates[indexDate];

                    let date = moment(`${month}/${day}/${year}`);

                    if(repeatTimes === 0){ // FOR UNDEFINITE IN REPEAT TIMES
                        if(recurring && recurring === 'weekly'){
                            let dateStart = moment(start.format('MM/DD/YYYY')).add( date.day() - start.day() ,'d');
                            let dateEnd = moment(end.format('MM/DD/YYYY')).add( date.day() - end.day(), 'd');
                            let dateToObj;
                            if( dateEnd.isValid() && dateEnd.isBetween(start, end, undefined, '[]'))
                                dateToObj = dateEnd;
                            else if(dateStart.isValid() && dateStart.isBetween(start, end, undefined, '[]'))
                                dateToObj = dateStart;

                            if(dateToObj){
                                finalSpecialRates.push({
                                    date: dateToObj,
                                    code: response.specialRates[index].code,
                                    dateDesc: dateToObj.format('dddd'),
                                    recurrTime: `∞${recurring
                                        ? recurring === 'weekly'
                                            ?  repeatTimes === 1 ? 'wk' : 'wks'
                                            : recurring === 'monthly'
                                                ? repeatTimes === 1 ? 'mo' : 'Mos'
                                                : repeatTimes === 1 ? 'yr' : 'yrs'
                                        : ''}`,
                                    startDate: date.format('MM/DD/YYYY'),
                                    vehicles: response.specialRates[index].vehicles,
                                    startTime: time ? moment(time.startTime, 'HH:mm').format('LT') : 'None',
                                    endTime: time ? moment(time.endTime, 'HH:mm').format('LT') : 'None',
                                });
                            }
                        } else {
                            let dateStart = moment(`${start.month() + 1}/${day}/${start.year()}`);
                            let dateEnd = moment(`${end.month() + 1}/${day}/${end.year()}`);
                            let dateToObj;
                            if( dateEnd.isValid() && dateEnd.isBetween(start, end, undefined, '[]') )
                                dateToObj = dateEnd;
                            else if(dateStart.isValid() && dateStart.isBetween(start, end, undefined, '[]') )
                                dateToObj = dateStart;

                            if(dateToObj){
                                finalSpecialRates.push({
                                    date: dateToObj,
                                    code: response.specialRates[index].code,
                                    dateDesc: recurring && recurring === 'monthly'
                                        ? dateToObj.format('Do') : dateToObj.format('MM/DD/YYYY'),
                                    recurrTime: `∞${recurring
                                        ? recurring === 'weekly'
                                            ?  repeatTimes === 1 ? 'wk' : 'wks'
                                            : recurring === 'monthly'
                                                ? repeatTimes === 1 ? 'mo' : 'Mos'
                                                : repeatTimes === 1 ? 'yr' : 'yrs'
                                        : ''}`,
                                    startDate: date.format('MM/DD/YYYY'),
                                    vehicles: response.specialRates[index].vehicles,
                                    startTime: time ? moment(time.startTime, 'HH:mm').format('LT') : 'None',
                                    endTime: time ? moment(time.endTime, 'HH:mm').format('LT') : 'None',
                                });
                            }
                        }

                    } else {

                        //let initialDate = moment(`${month}/${day}/${year}`);
                        let initialDate = moment(date.format('MM/DD/YYYY'))
                        for(let count = 0; count < repeatTimes; count++){
                            if(date.isBetween(start, end, undefined, '[]')){
                                finalSpecialRates.push({
                                    code: response.specialRates[index].code,
                                    dateDesc: recurring
                                        ? recurring === 'weekly'
                                            ? date.format('dddd')
                                            : recurring === 'monthly'
                                                ? date.format('Do')
                                                : date.format('MM/DD/YYYY')
                                        : date.format('MM/DD/YYYY'),
                                    recurrTime: `${repeatTimes}${recurring
                                        ? recurring === 'weekly'
                                            ?  repeatTimes === 1 ? 'wk' : 'wks'
                                            : recurring === 'monthly'
                                                ? repeatTimes === 1 ? 'mo' : 'Mos'
                                                : repeatTimes === 1 ? 'yr' : 'yrs'
                                        : ''}`,
                                    startDate: initialDate.format('MM/DD/YYYY'),
                                    vehicles: response.specialRates[index].vehicles,
                                    startTime: time ? moment(time.startTime, 'HH:mm').format('LT') : 'None',
                                    endTime: time ? moment(time.endTime, 'HH:mm').format('LT') : 'None',
                                });
                            }
                            date.add(1, typeAdd);
                        }
                    }

                }
            }

            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Special Rates",
                Subject: "Locations",
                Author: "BBC EXPRESS",
                CreatedDate: new Date(),
            };

            // Head of workbook in spreadsheet
            let ws_data = [[ "CODE", "DATE DESC", "RECUR TIME", "START DATE", "VEH CODE", "START TIME", "END TIME"]];

            // Create the array of data for add to Spreadsheet
            for(let index = 0; index < finalSpecialRates.length; index++){
                for(let indexVehicle = 0; indexVehicle < finalSpecialRates[index].vehicles.length; indexVehicle++){
                    ws_data.push([
                        `${finalSpecialRates[index].code}`,
                        `${finalSpecialRates[index].dateDesc}`,
                        `${finalSpecialRates[index].recurrTime}`,
                        `${finalSpecialRates[index].startDate}`,
                        `${finalSpecialRates[index].vehicles[indexVehicle].vehicleInfo.vehicleCode}`,
                        `${finalSpecialRates[index].startTime}`,
                        `${finalSpecialRates[index].endTime}`,
                    ]);
                }
            }
            const ws = XLSX.utils.aoa_to_sheet(ws_data);

            // Columns width in spreadsheet
            let wscols = [ {wch:10}, {wch:15}, {wch:10}, {wch:15}, {wch:15}, {wch:15}, {wch:15} ];
            ws['!cols'] = wscols;

            // Add the data to new sheet in the spreadsheet
            XLSX.utils.book_append_sheet(wb, ws, "Transfer Reservations");
            const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
            const s2ab = (s) => {
                const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
                const view = new Uint8Array(buf); // create uint8array as viewer
                for (let i = 0; i < s.length; i++)
                    view[i] = s.charCodeAt(i) & 0xff; // convert to octet
                return buf;
            }

            // Save the spreadsheet
            saveAs(
                new Blob([s2ab(wbout)], { type: "application/octet-stream", }),
                'Special Rates.xlsx'
            );

        }

    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch({ type: LOADER_MODAL_REPORT, loaderReport: false });
        dispatch(closeModalReport());
    }
}

const getLocationHeader = (id) => async (dispatch, getStore) => {
    dispatch(loading());
    await app.service('/api/location').get(id, { query: {
        $select: [ 'address', 'city', 'cityCode', 'createdAt', 'lat', 'lng', 'state', 'updatedAt']
    }}).then(response => {
        const { updateCustomLabel } = breadcrumbActions;
        let title = `${response.city}, ${response.state} (${response.cityCode})`
        if (title.length > 22) {
            title = `${title.slice(0, 23)}...`;
        }

        dispatch(updateCustomLabel(id, title));
        dispatch(setDataHeader(response));
    }).catch(error=>{
        dispatch(errorHandler(error));
    }).finally(()=>{
        dispatch(loading(false));
    })
}

const openReportModal = () => (dispatch) => {
    dispatch({ type: MODAL_REPORT, modalReport: true });
}

const closeModalReport = () => (dispatch, getStore) => {
    dispatch(destroyForm('reportSpecialRatesForm'));
    dispatch({ type: MODAL_REPORT, modalReport: false });
}

export const updateSpecialRatesFromOdoo = () => async (dispatch, getStore) => {
    dispatch(loading())
    try {
        const result = await service.find({
            query: { updateOdooSpecialRates: true }
        })
        if (result) {
            swal.fire({
                type: "success",
                title: "SUCCESS!",
                text: "Data have been updated",
                confirmButtonColor: '#D50032',
            })
        }
    } catch (error) {
        console.log(error);
        errorHandler(error)
    } finally {
        dispatch(loading(false));
    }
}

const setDataToCreate = (dataToCreateSpecialRate) => ({
    type: DATA_CREATE_SPECIAL_RATE,
    dataToCreateSpecialRate,
})

const setSpecialRate = (updateDataEspecialRate) => ({
    type: UPDATE_DATA_SPECIAL_RATE,
    updateDataEspecialRate
})

const clear = () => (dispatch) => {
    dispatch(setUpdateData({}));
    dispatch(setDataToCreate({}));
}

const closeEditVehicle = () => (dispatch) => {
    dispatch(setVehicleEdit({}))
    dispatch(openCloseModal(false));
}

const setVehicleEdit = (value) => ({
    type: SET_VEHICLE_EDIT,
    vehicleEdit: value,
})

const setModalLoading = (value) => ({
    type: VEHICLE_LOCATION_LOADER,
    vehicleLocationLoader: value,
})

const openCloseModal = (value) => ({
    type: MODAL_VEHICLE_LOCATION,
    modalVehicleEdit: value
});

const setDataHeader = (header) => ({
    type: LOCATIONS_HEADER,
    header,
})
// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_ZIP_FILTER]: (state, { zipFilter }) => ({ ...state, zipFilter }),
    [SET_EXCLUDE_ZIP_FILTER]: (state, { excludeZipFilter }) => ({ ...state, excludeZipFilter}),
    [SET_UPDATE_DATA]: (state, { updateData }) => ({ ...state, updateData }),
    [PAGE]: (state, { page }) => ({ ...state, page }),
    [SEARCH_LOCATION]: ( state, { search }) => ({ ...state, search }),
    [SET_VEHICLE_EDIT]: ( state, { vehicleEdit }) => ({ ...state, vehicleEdit }),
    [MODAL_VEHICLE_LOCATION]: ( state, { modalVehicleEdit }) => ({ ...state, modalVehicleEdit }),
    [VEHICLE_LOCATION_LOADER]: ( state, { vehicleLocationLoader }) => ({ ...state, vehicleLocationLoader }),
    [MODAL_SPECIAL_RATES]: (state, { modalSpecialRates }) => ({ ...state, modalSpecialRates }),
    [SPECIAL_RATES_LOADER]: (state, { specialRatesLoader }) => ({ ...state, specialRatesLoader }),
    [UPDATE_DATA_SPECIAL_RATE]: ( state, { updateDataEspecialRate }) => ({ ...state, updateDataEspecialRate }),
    [DATA_CREATE_SPECIAL_RATE]: ( state, { dataToCreateSpecialRate }) => ({ ...state, dataToCreateSpecialRate  }),
    [SEARCH_SPECIAL_RATE]: ( state, { loadingSpecialRates }) => ({ ...state, loadingSpecialRates }),
    [MODAL_REPORT]: ( state, { modalReport }) => ({ ...state, modalReport }),
    [LOADER_MODAL_REPORT]: (state, { loaderReport }) => ({ ...state, loaderReport }),
    [LOCATIONS_HEADER]: (state, { header }) => ({ ...state, header }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    header: undefined,
    search: undefined,
    loading: 0,
    updateData: {},
    vehicleEdit: {},
    modalReport: false,
    loaderReport: false,
    modalVehicleEdit: false,
    modalSpecialRates: false,
    specialRatesLoader: false,
    loadingSpecialRates: false,
    updateDataEspecialRate: {},
    dataToCreateSpecialRate: {},
    vehicleLocationLoader: false,
    excludeZipFilter: [],
    zipFilter: [],
    page: 1,
};

export const actions = {
    clear,
    create,
    setCity,
    update,
    load2Update,
    setAddress,
    setState,
    setSearch,
    getVehicles,
    restoreDefault,
    removeBaseRate,
    generarteReport,
    openReportModal,
    closeModalReport,
    openEditVehicle,
    verifySpecialRates,
    getLocationHeader,
    changeRadioChecks,
    removeSpecialRate,
    closeSpecialRates,
    resetInputCalendar,
    submitEspecialRate,
    seatSearchSpecialRate,
    openModalSpecialRates,
    updateSpecialRateVehicle,
    loadEditVehicleSpecialRate,
    verifyBaseRatesCreate,
    updateVehicleLocation,
    createVehicleLocation,
    closeEditVehicle,
};

export default handleActions(reducers, initialState);
