import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import app from "../../libs/apiClient";
import XLSX from "xlsx";
import {
    errorHandler,
    prefixList,
    educationList,
    positionList,
    availabilityList,
    shiftList,
    reasonLeavingList,
    dlTypeList,
    experienceList,
    statusList
} from '../../common/utility/constants';
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";
import swal from "sweetalert2";
import moment from "moment";

const service = app.service("/api/apply-driver");

const LOADING_GRID = "APPLY_LOADING_GRID";
const LOADING_FORM = "APPLY_LOADING_FORM";
const SET_DATA = "APPLY_SET_DATA";
const SET_ITEM = "APPLY_SET_ITEM";
const SET_PAGE = "APPLY_SET_PAGE";
const SET_SEARCH = "APPLY_SET_SEARCH";
const FILTERS = "APPLY_FILTERS";
const SET_EDIT = "APPLY_SET_EDIT";

// Actions
const setLoadingGrid = (loading) => ({ type: LOADING_GRID, loading });
const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
const setData = (data) => ({ type: SET_DATA, data });
const setItem = (item) => ({ type: SET_ITEM, item });
const setPage = (page) => ({ type: SET_PAGE, page });
const setSearchString = (search) => ({ type: SET_SEARCH, search });
const setFiltersValue = (filters) => ({ type: FILTERS, filters });
const setEdit = (isEdit) => ({ type: SET_EDIT, isEdit });

export const create = (values) => (dispatch) => {
    const payload = {
        ...values,
        work_before: values.work_before.value, 
        status: values.status.value,  
        source: 'portal',
    };

    dispatch(setLoadingForm(true));
    service.create(payload).then(() => {
        dispatch(goBack());
    })
    .catch((e) => {
        dispatch(errorHandler(e));
    })
    .finally(() => {
        dispatch(setLoadingForm(false));
    });
};

export const update = (values) => async (dispatch) => {
    try {
        dispatch(setLoadingForm(true));
        const result = await swal.fire({
            type: "warning",
            title: "Are you sure?",
            text: "This action will update the form application",
            showCancelButton: true,
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        });
        if (result.value) {
            const data = {
                ...values,
                dot_license: values.dot_license.reduce((acum, curr) => {
                    const d = {
                        ...curr,
                        dl_type: curr.dl_type && curr.dl_type.value ? curr.dl_type.value : curr.dl_type,
                    }
                    acum.push(d);
                    return acum;
                }, []),
                prefix: values.prefix && values.prefix.value ? values.prefix.value : values.prefix,
                state: values.state && values.state.value ? values.state.value : values.state,
                education: values.education && values.education.value ? values.education.value : values.education,
                position: values.position && values.position.value ? values.position.value : values.position,
                availability: values.availability && values.availability.value ? values.availability.value : values.availability,
                shift: values.shift && values.shift.value ? values.shift.value : values.shift,
                reason_leaving: values.reason_leaving && values.reason_leaving.value ? values.reason_leaving.value : values.reason_leaving,
                dl_type: values.dl_type && values.dl_type.value ? values.dl_type.value : values.dl_type,
                issue_state: values.issue_state && values.issue_state.value ? values.issue_state.value : values.issue_state,
                experience: values.experience && values.experience.value ? values.experience.value : values.experience,
                status: values.status && values.status.value ? values.status.value : values.status
            }
            await service.patch(values._id, data);
            await swal.fire({
                type: "success",
                title: "SUCCESS",
                text: "The driver request form has been submitted"
            });
        }
        dispatch(setIsEdit(false));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setLoadingForm(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    const result = await swal.fire({
        type: "warning",
        title: "Are you sure?",
        text: "You cannot undo this action!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    });
    if (result.value) {
        try {
            dispatch(setLoadingGrid(true));
            await service.remove(id);
            swal.fire({
                type: "success",
                title: "SUCCESS",
                text: "Data Removed"
            })
            dispatch(find());
        } catch (e) {
            dispatch(errorHandler(e));
        } finally {
            dispatch(setLoadingGrid(false));
        }
    }
};

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(setLoadingGrid(true));

    try {
        const { search, filters } = getState().apply;
        const query = { query: { $skip: (page - 1) * 10 } };
        if(search || Object.values(filters).some(f => !!f)) {
            query.query.$or = [];
            const search_first_name = filters.first_name ? filters.first_name : search;
            const search_middle_name = filters.middle_name ? filters.middle_name : search;
            const search_last_name = filters.last_name ? filters.last_name : search;
            const search_phone = filters.phone ? filters.phone : search;
            const search_email = filters.email ? filters.email : search;
            const search_experience = filters.experience ? filters.experience : search;
            const search_city = filters.city ? filters.city : search;
            const search_state = filters.state ? filters.state : search;

            if (search_first_name)
                query.query.$or.push({ first_name: { $regex: _.escapeRegExp(search_first_name), $options: 'i' } });
            if (search_middle_name)
                query.query.$or.push({ middle_name: { $regex: _.escapeRegExp(search_middle_name), $options: 'i' } });
            if (search_last_name)
                query.query.$or.push({ last_name: { $regex: _.escapeRegExp(search_last_name), $options: 'i' } });
            if (search_phone)
                query.query.$or.push({ phone: { $regex: _.escapeRegExp(search_phone) , $options: 'i'} });
            if (search_email)
                query.query.$or.push({ email: { $regex: _.escapeRegExp(search_email) , $options: 'i'} });
            if (search_experience)
                query.query.$or.push({ experience: { $regex: _.escapeRegExp(search_experience) , $options: 'i'} });
            if (search_city)
                query.query.$or.push({ city: { $regex: _.escapeRegExp(search_city) , $options: 'i'} });
            if (search_state)
                query.query.$or.push({ state: { $regex: _.escapeRegExp(search_state) , $options: 'i'} });
        }
        const response = await service.find(query);
        for(let i=0; i<response.data.length; i+=1) {
            if (response.data[i].birth) {
                const birth = moment(response.data[i].birth);
                const today = moment();
                response.data[i].age = Math.abs(birth.diff(today, 'y'));
            }
            if (response.data[i].prefix)
                response.data[i].prefix = prefixList.find(item => item.value === response.data[i].prefix) || response.data[i].prefix
            if (response.data[i].education)
                response.data[i].education = educationList.find(item => item.value === response.data[i].education) || response.data[i].education
            if (response.data[i].position)
                response.data[i].position = positionList.find(item => item.value === response.data[i].position) || response.data[i].position
            if (response.data[i].availability)
                response.data[i].availability = availabilityList.find(item => item.value === response.data[i].availability) || response.data[i].availability
            if (response.data[i].shift)
                response.data[i].shift = shiftList.find(item => item.value === response.data[i].shift) || response.data[i].shift
            if (response.data[i].reason_leaving)
                response.data[i].reason_leaving = reasonLeavingList.find(item => item.value === response.data[i].reason_leaving) || response.data[i].reason_leaving
            if (response.data[i].dl_type)
                response.data[i].dl_type = dlTypeList.find(item => item.value === response.data[i].dl_type) || response.data[i].dl_type
            if (response.data[i].experience)
                response.data[i].experience = experienceList.find(item => item.value === response.data[i].experience) || response.data[i].experience
            if (response.data[i].status)
                response.data[i].status = statusList.find(item => item.value === response.data[i].status) || response.data[i].status
            if (response.data[i].dot_license){
                response.data[i].dot_license = response.data[i].dot_license.reduce((acum, curr) => {
                    const d = {
                        ...curr,
                        dl_type: dlTypeList.find(item => item.value === curr.dl_type) || curr.dl_type
                    }
                    acum.push(d);
                    return acum;
                }, []);
            }
        }
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setLoadingGrid(false));
    }
};

export const load2Update = (id, initializeFormValues = false) => async (dispatch, getState) => {
    dispatch(setLoadingForm(true));

    try {
        const details = await service.get(id);
        if (details.prefix)
            details.prefix = prefixList.find(item => item.value === details.prefix) || details.prefix
        if (details.education)
            details.education = educationList.find(item => item.value === details.education) || details.education
        if (details.position)
            details.position = positionList.find(item => item.value === details.position) || details.position
        if (details.availability)
            details.availability = availabilityList.find(item => item.value === details.availability) || details.availability
        if (details.shift)
            details.shift = shiftList.find(item => item.value === details.shift) || details.shift
        if (details.reason_leaving)
            details.reason_leaving = reasonLeavingList.find(item => item.value === details.reason_leaving) || details.reason_leaving
        if (details.dl_type)
            details.dl_type = dlTypeList.find(item => item.value === details.dl_type) || details.dl_type
        if (details.experience)
            details.experience = experienceList.find(item => item.value === details.experience) || details.experience
        if (details.status)
            details.status = statusList.find(item => item.value === details.status) || details.status
        if (details.dot_license){
            details.dot_license = details.dot_license.reduce((acum, curr) => {
                const d = {
                    ...curr,
                    dl_type: dlTypeList.find(item => item.value === curr.dl_type) || curr.dl_type
                }
                acum.push(d);
                return acum;
            }, []);
        }

        dispatch(setItem(details));

        if (!initializeFormValues){
            const full_name = `${details.prefix && details.prefix.label ? details.prefix.label : details.prefix} ${details.first_name || ''}${details.middle_name ? ` ${details.middle_name}`: ''}${details.last_name ? ` ${details.last_name}`: ''}`;
            details.full_name = full_name;
            if (details.vehicle_type) {
                const vehicles = {
                    sedans: 'Sedans',
                    suv: 'SUV',
                    vans: 'Vans',
                    bus: 'Bus',
                    coach: 'Coach'
                };
                let label = "";
                for(let key in details.vehicle_type){
                    if (details.vehicle_type[key])
                        label += (label.length > 0 ? ", " : "") + `${vehicles[key] || key}`;
                }
                details.vehicle_type.label = label;
            }
            const { updateCustomLabel } = breadcrumbActions;
            dispatch(updateCustomLabel(id, full_name));
        } else {
            dispatch(initializeForm("applyForm", details));
        }
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setLoadingForm(false));
    }
};

export const setSearch = (search) => (dispatch) => {
    dispatch(setSearchString(search));
    dispatch(find());
}

export const setFilters = (filters) => (dispatch) => {
    dispatch(setFiltersValue(filters));
    dispatch(find());
}

export const downloadData = () => async (dispatch, getStore) => {
    dispatch(setLoadingGrid(true));
    try {
        const result = await service.find({
            query: {
                $paginate: false,
            }
        })

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Applications",
            Subject: "Applications",
            Author: "BBC EXPRESS",
            CreatedDate: new Date(),
        };

        // Head of workbook in spreadsheet
        const wsData = [["Full Name", "Phone", "Email Address", "Experience", "Full Address", "DOB", "State", "Shift", "Desired Income", "Availability", "Application Date"]];

        // Create the array of data for add to Spreadsheet
        for (let index = 0; index < result.length; index += 1){
            if (result[index].birth) {
                const birth = moment(result[index].birth);
                const today = moment();
                result[index].age = Math.abs(birth.diff(today, 'y'));
            }
            const full_name = `${result[index].prefix} ${result[index].first_name || ''}${result[index].middle_name ? ` ${result[index].middle_name}`: ''}${result[index].last_name ? ` ${result[index].last_name}`: ''}`;
            const shift = result[index].shift == 'other' && result[index].custom_shift
                ? result[index].custom_shift
                : shiftList.find(item => item.value === result[index].shift)
            const availability = availabilityList.find(item => item.value === result[index].availability);
            wsData.push([
                full_name,
                result[index].phone,
                result[index].email,
                result[index].experience.replace('_y', ' Y'),
                `${result[index].address || ''}${result[index].city ? `, ${result[index].city}` : ''}${result[index].state ? `, ${result[index].state}` : ''}${result[index].zip ? `, ${result[index].zip}` : ''}`,
                `${result[index].birth ? moment(result[index].birth).format('MM/DD/YYYY') : ''}`,
                `${result[index].state}`,
                `${shift ? shift.label : result[index].shift}`,
                result[index].month_salary,
                `${availability ? availability.label : result[index].availability}`,
                `${result[index].createdAt ? moment(result[index].createdAt).format('MM/DD/YYYY') : ''}`
            ]);
        }
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Columns width in spreadsheet
        const wscols = [{wch:25}, {wch:17}, {wch:25}, {wch:12}, {wch:12}, {wch:10}, {wch:12}, {wch:20}, {wch:10}, {wch:15}, {wch:20}];
        ws["!cols"] = wscols;

        // Add the data to new sheet in the spreadsheet
        XLSX.utils.book_append_sheet(wb, ws, "Applications");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
            const view = new Uint8Array(buf); // create uint8array as viewer
            for (let i = 0; i < s.length; i += 1)
                view[i] = s.charCodeAt(i) & 0xff; // convert to octet
            return buf;
        };

        // Save the spreadsheet
        saveAs(
            new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
            `Applications.xlsx`
        );
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingGrid(false));
    }
}

export const changeStateApply = (status) => async (dispatch, getStore) => {
    const result = await swal.fire({
        type: "warning",
        title: "Are you sure?",
        text: "This action will update the form application",
        showCancelButton: true,
        confirmButtonText: "YES",
        cancelButtonText: "NO",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    });
    if (result.value) {
        dispatch(setLoadingForm(true));
        try {
            const { item } = getStore().apply;
            await service.patch(item._id, { status });
            dispatch(load2Update(item._id));
        } catch (error) {
            dispatch(errorHandler(error));
        } finally {
            dispatch(setLoadingForm(false));
        }
    }
}

export const setIsEdit = (value) => (dispatch, getStore) => {
    dispatch(setEdit(value));
    const { item } = getStore().apply;
    dispatch(load2Update(item._id, value));
}

export const actions = {
    find, 
    load2Update,
    setSearch,
    setFilters,
    downloadData,
    setIsEdit,
    changeStateApply,
    destroy,
    update,
    create,
}

// Reducers
const reducers = {
    [LOADING_GRID]: (state, { loading }) => ({ ...state, loadingGrid: loading }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_SEARCH]: (state, { search }) => ({ ...state, search }),
    [SET_EDIT]: (state, { isEdit }) => ({ ...state, isEdit }),
    [FILTERS]: (state, { filters }) => ({ ...state, filters }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    filters: {},
    page: 1,
    loadingGrid: false,
    loadingForm: false,
    isEdit: false,
    search: '',
};

export default handleActions(reducers, initialState);
