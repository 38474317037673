import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderSearchSelect, renderField, renderSelectField, renderLabeledField2, renderTextArea } from '../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { loadMainVehicles, loadFleetDrivers } from '../../../../redux/modules/expenseTickets';
import renderDatePicker from '../../Utils/renderField/renderDatePicker'

const DownTimeForm = (props) => {
  const { handleSubmit, formValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="container p-4">
      <div className="row mb-3">
          <div className="col-md-6">
            <strong>Start Date/Time</strong>
            <Field
              name="startDate"
              component={renderDatePicker}
              placeholder="12/31/2024 12:00 PM"
              numberOfMonths={1}
              showButton={false}
              className="p-0"
            />
          </div>
          <div className="col-md-6 pt-3">
          <label htmlFor="gap">
                        <Field
                            id="gap"
                            component={"input"}
                            type="checkbox"
                            name={'gap'}
                        />
                        &nbsp;Gap was greater than 3 hours.<strong style={{color:'red'}}>*</strong>
                    </label>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <strong>Driver</strong>
            <Field
              name="driver"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadFleetDrivers}
              allKey
            />
          </div>
          <div className="col-md-6">
            <strong>Vehicle</strong>
            <Field
              name="vehicle"
              component={renderSearchSelect}
              className="form-control"
              labelKey="label"
              valueKey="_id"
              loadOptions={loadMainVehicles}
              allKey
            />
          </div>
        </div>
       
        <div className="row mb-3">
              <div className='col-12'>
              <strong>Notes</strong>
              <Field
                        name="notes"
                        component={renderTextArea}
                        rows={4}
                        placeholder="Notes..."
                        className="w-100"
                    />
              </div>
        </div>

        <div className="text-center mt-2">
          <button type="submit" className="btn btn-primary btn-lg w-100">Save</button>
        </div>
      </div>
    </form>
  );
};


const mapStateToProps = (state) => ({
  formValues: (state.form.downTimeForm && state.form.downTimeForm.values) ? state.form.downTimeForm.values : {}
});

export default connect(mapStateToProps)(reduxForm({
  form: 'downTimeForm',
  validate:(data)=>{
    const errors = {}
    if (!data.driver) errors.driver = "Required Field";
    if (!data.vehicle) errors.vehicle = "Required Field";
    if (!data.startDate) errors.startDate = "Required Field";
  
    return errors
}
})(DownTimeForm));
