import React from "react";
import Form from "./ClientForm";
import LoadMask from "../../Utils/LoadMask";

export default class Create extends React.Component {
    render() {
        const { create, userInfo: { permission }, loading } = this.props;

        return (
            <div className="row d-flex justify-content-center">
                {permission && permission.find(({ createClients }) => !!createClients) && (
                    <LoadMask loading={loading} blur>
                        <Form onSubmit={create} />
                    </LoadMask>
                )}
            </div>
        );
    }
}
