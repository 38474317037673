import React, { useEffect, useState } from "react";
import ModalForm from "./Modal";
import './customSelectStyles.css';
import LoadDatesForm from './forms/LoadDatesForm';
import { loadMainVehicles } from "../../../redux/modules/expenseTickets";
import app from "../../../libs/apiClient";
import LoadMask from '../Utils/LoadMask';
const service = app.service("api/expenseTicketType");
const ticketService = app.service("api/expenseTicket");
import XLSX from 'xlsx';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS and Popper.js
import 'jquery'; // Import jQuery

const ExpenseTickets = () => {

  const [showModal, setShowModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState("");
  const [types, setTypes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTickets, setFilteredTickets] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSubmit = (formValues) => {

    if (Object.keys(formValues).length > 0) {
      getTickets(formValues);
    }

  };

  const handleSelectChange = (expenseType) => {
    if (expenseType) {
      setSelectedExpense(expenseType);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedExpense('')
  };

  const getTypes = async () => {
    const result = await service.find('getTicketHelp')
    setTypes(result)
  }

  const getTickets = async (formValues) => {
    try {
      setLoading(true)
      const result = await ticketService.find({ query: { formValues } });
      if (result) {
        setTickets(result)
        setFilteredTickets(result)
        setLoading(false)
      } else {
        setLoading(false)
      }

    } catch (error) {
      return { error: error.message }
    }

  }

  useEffect(() => {
    getTypes()
  }, [])

  useEffect(() => {
    const filtered = tickets.filter((item) => {
      return (
        item.expense_type_id.name.toLowerCase().includes(searchTerm) ||
        item.driver_name.toLowerCase().includes(searchTerm) ||
        item.vehicle_name.toLowerCase().includes(searchTerm) ||
        item.start_datetime.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredTickets(filtered);
  }, [tickets, searchTerm]);

  const downloadTicketsFile = (fileTitle, fileTickets) => {
    if (!fileTickets || !Array.isArray(fileTickets)) {
        console.error("fileTickets is undefined or not an array");
        return;
    }

    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: fileTitle,
        Subject: "Tickets",
        Author: "Your Company Name",
        CreatedDate: new Date(),
    };

    const ws_data = [
        [
            "Compensation Type", 
            "Driver Name", 
            "Vehicle Name", 
            "Trip Type", 
            "Total Hours", 
            "Date", 
            "Additional Details", 
            "Created By", 
            "Create Date", 
            "Livery Id"
        ]
    ];

    fileTickets.forEach(ticket => {
        ws_data.push([
          (ticket.expense_type_id && ticket.expense_type_id.name) || '', 
            ticket.driver_name || '', 
            ticket.vehicle_name || '', 
            ticket.trip_type || '', 
            ticket.total_hours != null ? ticket.total_hours.toFixed(2) : '',
            ticket.start_datetime || '', 
            ticket.po_field || '', 
            ticket.created_by || '', 
            moment(ticket.createdAt).format('YYYY-MM-DD HH:mm') || '', 
            ticket.livery_id || ''
        ]);
    });

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const headerStyle = {
        font: { bold: true },
        alignment: { horizontal: 'center' }
    };

    const columnsCount = 10; // Número de columnas
    for (let col = 0; col < columnsCount; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        ws[cellAddress].s = headerStyle;
    }

    const wscols = Array(columnsCount).fill({ wch: 20 }); 
    ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, fileTitle);

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${fileTitle}_tickets.xlsx`);
};


  return (
    <div className="container mt-1" >
      <div className="row">

      {/* Title in the center */}
      <div className="col-12 text-center mb-2">
        <h2>Driver Compensation</h2>
      </div>

      <div className="col-12 col-md-2">
        <div className="dropdown">
          <button
            className="btn btn-dark dropdown-toggle form-control"
            type="button"
            id="expenseDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {selectedExpense ? selectedExpense : (
              <React.Fragment>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="15"
                  height="15"
                  style={{ fill: "#fff", marginRight: "5px" }}
                >
                  <path d="M432 256c0 13.3-10.7 24-24 24h-136v136c0 13.3-10.7 24-24 24s-24-10.7-24-24V280H88c-13.3 0-24-10.7-24-24s10.7-24 24-24h136V88c0-13.3 10.7-24 24-24s24 10.7 24 24v120h136c13.3 0 24 10.7 24 24z" />
                </svg>
                
                ADD
              </React.Fragment>
            )}
          </button>
          <div className="dropdown-menu" aria-labelledby="expenseDropdown">
            <button className="dropdown-item" onClick={() => handleSelectChange("Backup Driver")}>
              Backup Driver
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Breakdown")}>
              Breakdown
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Car Wash")}>
              Car Wash
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Deadhead")}>
              Deadhead
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Delayed Passengers")}>
              Delayed Passengers
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Delivery")}>
              Delivery
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Downtime")}>
              Downtime
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Employee Pickup")}>
              Employee Pickup
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Missed Cancellation")}>
              Missed Cancellation
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Missed Trip")}>
              Missed Trip
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Service or Repair")}>
              Service or Repair
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Training")}>
              Training
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Vehicle Inspection")}>
              Vehicle Inspection
            </button>
            <button className="dropdown-item" onClick={() => handleSelectChange("Vehicle Swap")}>
              Vehicle Swap
            </button>
          </div>
        </div>
      </div>

        <div className="col-12 col-md-8">
          <LoadDatesForm onSubmit={handleSubmit} />

          <div className="form-group">
            <input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
          </div>

          {/* Button aligned to the right */}
          <div className="d-flex justify-content-end mb-2">
            <button
              type="button"
              onClick={() => downloadTicketsFile("expense-tickets", tickets)}
              style={{
                display: "flex",
                alignItems: "center",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                width="15"
                height="15"
                style={{ fill: "#333", marginRight: "5px" }}
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
              Download
            </button>
          </div>

          <LoadMask loading={loading} blur>
            <div
              style={{
                height: "600px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {filteredTickets.length > 0 ? (
                filteredTickets.map((item) => (
                  <div key={item._id} className="card mt-2 position-relative">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column w-50">
                          <div className="p-2">
                            <span className="badge bg-secondary text-white">{item.expense_type_id.name}</span>
                          </div>
                          <div className="p-2">
                            <strong>Driver ID: </strong> {item.driver_fleetioId}
                          </div>
                        </div>

                        <div className="d-flex flex-wrap justify-content-start">
                          <div className="p-2">
                            <strong>Date: </strong>{item.start_datetime.split(" ")[0]}
                          </div>
                          <div className="p-2">
                            <strong>Time: </strong>{item.start_datetime.split(" ")[1]}
                          </div>
                          <div className="p-2">
                            <strong>Driver: </strong>{item.driver_name}
                          </div>
                          <div className="p-2">
                            <strong>Vehicle: </strong> {item.vehicle_name}
                          </div>
                          <div className="p-2">
                            <strong>Total Hours: </strong>{item.total_hours != null ? item.total_hours.toFixed(2) : ''}
                          </div>
                          <div className="p-2">
                            <strong>Livery ID: </strong> {item.livery_id}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>No Data</p>
              )}
            </div>
          </LoadMask>
        </div>

      </div>

      {/* Modal */}
      <ModalForm showModal={showModal} handleCloseModal={handleCloseModal} selectedExpense={selectedExpense} types={types} />

    </div>
  );
};

export default ExpenseTickets;
