import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-responsive-modal';
import BackUpDriverForm from './forms/BackUpDriverForm';
import BreakdownForm from './forms/BreakdownForm';
import CarWashForm from './forms/CarWashForm';
import DeadHeadForm from './forms/DeadHeadForm';
import DelayedForm from './forms/DelayedForm';
import DeliveryForm from './forms/DeliveryForm';
import DownTimeForm from './forms/DownTimeForm';
import EmployeePickuoForm from './forms/EmployeePickuoForm';
import MissedCancellationForm from './forms/MissedCancellationForm';
import MissedTripForm from './forms/MissedTripForm';
import ServiceRepairForm from './forms/ServiceRepairForm';
import TrainingForm from './forms/TrainingForm';
import VehicleSwapForm from './forms/VehicleSwapForm';
import VehicleInspactionForm from './forms/VehicleInspactionForm';
import app from "../../../libs/apiClient";
import { connect } from 'react-redux';
import moment from 'moment';
import swal from 'sweetalert2';

const service = app.service("api/expenseTicket");

const ModalForm = ({ showModal, handleCloseModal, selectedExpense, from, to, types = [] }) => {

    const [showInnerModal, setShowInnerModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [modalHeight, setModalHeight] = useState('auto');
    const modalRef = useRef(null);
    const buttonRef = useRef(null);

    const openInnerModal = () => {
        const buttonRect = buttonRef.current.getBoundingClientRect(); // Obtener posición del botón
        setModalPosition({ top: buttonRect.top - 80, left: buttonRect.left - 5 }); // Ajustar la posición
        setShowInnerModal(true);
    };
    const closeInnerModal = () => setShowInnerModal(false);

    useEffect(() => {
        if (showModal && modalRef.current) {
            const modalElement = modalRef.current;
            const height = modalElement.offsetHeight;
            setModalHeight(`${height + 33.5}px`);
        }
    }, [showModal]);

    const handleSubmit = async (formValues) => {
        try {

            const formData = {
                formName: selectedExpense,
                ...formValues,
                formattedDate: moment(formValues.startDate).format('YYYY-MM-DD HH:mm'),
                startLocation: {
                    name: formValues.startLocation,
                    details: { from }
                },
                endLocation: {
                    name: formValues.endLocation,
                    details: { to }
                },
            };

            swal.fire({
                title: "Sending Form...",
                text: "Please wait",
                icon: 'info',
            });

            const response = await service.create(formData);
            if (response && response.error) {

                swal("Error", `Error: ${response.error || 'Unknown error'}`, "error");

            } else {
                swal("Success", "Ticket created succesfully.", "success");

                handleCloseModal();
            }

           

        } catch (error) {
            swal("Error", `Something went wrong: ${error.message || 'Unknown error'}`, "error");
            console.error('Error', error);
        }
    };



    const expenseForms = {
        'Backup Driver': BackUpDriverForm,
        'Breakdown': BreakdownForm,
        'Car Wash': CarWashForm,
        'Deadhead': DeadHeadForm,
        'Delayed Passengers': DelayedForm,
        'Delivery': DeliveryForm,
        'Downtime': DownTimeForm,
        'Employee Pickup': EmployeePickuoForm,
        'Missed Cancellation': MissedCancellationForm,
        'Missed Trip': MissedTripForm,
        'Service or Repair': ServiceRepairForm,
        'Training': TrainingForm,
        'Vehicle Swap': VehicleSwapForm,
        'Vehicle Inspection': VehicleInspactionForm
    };

    const SelectedForm = selectedExpense ? expenseForms[selectedExpense] : null;

    const [selectedTypes, setSelectedTypes] = useState([])

    useEffect(() => {
        if (types.length > 0) {
            const uniqueTypes = types.filter(type => type.name === `${selectedExpense}`)
            setSelectedTypes(uniqueTypes)
        }
    }, [selectedExpense])

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                styles={{ modal: { minWidth: '40%', borderRadius: '8px', marginTop: 60 } }}
            >
                <div className="p-5" ref={modalRef}>
                    <div className="text-center mb-3">
                        <h3 style={{ fontSize: 35 }}>{`${selectedExpense}`}</h3>
                    </div>
                    <div className="mt-3">
                        {SelectedForm && <SelectedForm onSubmit={handleSubmit} />}
                    </div>

                </div>
                <button
                    ref={buttonRef}
                    className="btn btn-secondary btn-floating"
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '-55px', 
                        zIndex: 1000,
                    }}
                    onClick={openInnerModal}
                >
                    <span style={{ width: "20px" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            width="20"
                            height="20"
                            style={{ fill: "white" }}
                        >
                            <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                        </svg>
                    </span>
                </button>
            </Modal>

            {showInnerModal && (
                <div
                    className="custom-modal"
                    style={{
                        position: 'absolute',
                        top: modalPosition.top,
                        left: modalPosition.left,
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        zIndex: 2000,
                        padding: '10px',
                        width: '310px',
                        height: modalHeight,//Debe ser del mismo alto de la modal de react-responsive-modal
                        overflow: 'scroll',
                        fontSize: '12px',
                        overflowX: 'hidden'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <h5>Rules</h5>
                        <label onClick={closeInnerModal} style={{ cursor: 'pointer' }}>
                            X
                        </label>
                    </div>

                    <ul>
                        {selectedTypes.length > 0 && selectedTypes[0].expense_typeHelp_id && selectedTypes[0].expense_typeHelp_id.description && selectedTypes[0].expense_typeHelp_id.description.length > 0 && (
                            selectedTypes[0].expense_typeHelp_id.description.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))
                        )}

                        {selectedTypes.length > 0 && selectedTypes[0].expense_typeHelp_id && selectedTypes[0].expense_typeHelp_id.example && selectedTypes[0].expense_typeHelp_id.example.length > 0 && (
                            <React.Fragment>
                                
                                {selectedTypes[0].expense_typeHelp_id.example.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </React.Fragment>
                        )}
                    </ul>

                    <h5>Not Applicable Scenarios</h5>
                    <ul>

                        <li>Trips unassigned before the late cancellation window but no more than 24hrs in
                            advance (e.g., airlines = 1hr, charter bus trip = 24hrs, etc.)
                        </li>
                        <li>Trips unassigned at anytime if the driver is reassigned to another trip (e.g., dispatch
                            changing routes). However, the driver is guaranteed pay for the trip with higher
                            compensation
                        </li>
                        <li>Trips missed or unassigned for any reasons caused by the driver that could have an
                            impact to customer service (i.e., driver negligence)
                        </li>
                        <li>Trips covered that were not dispatched/authorized in advance (e.g., picking up unknown
                            or other driver’s flight crew without authorization)
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>

    )
}

const mapStateToProps = (state) => ({
    from: state.priceCheck.from,
    to: state.priceCheck.to,
});

export default connect(mapStateToProps)(ModalForm);
