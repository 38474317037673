import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { actions } from '../../../../redux/modules/liveryEdition';
import LoadMask from '../../Utils/LoadMask';
import { formatFlightStatus } from './createForm';


const ModalVerify = (props) => {

    const { loadingVerifier, xmlFlightVerified, modalVerifyFlight, formValues,
            setModalVerifyFlight, useVerifyFlight, useOriginalVerifyFlight
        } = props;

    return (
        <Modal open={modalVerifyFlight}
            onClose={() => setModalVerifyFlight(false)}
            styles={{ modal: { minWidth: '30%', borderRadius: '8px' } }}
        >
            <div className="w-100">
                <LoadMask loading={loadingVerifier} blur>
                    <div className="d-flex flex-wrap p-4">
                        <div className="col-12">
                            {Object.keys(xmlFlightVerified).length > 0 && !xmlFlightVerified.noInfo ? (
                                <React.Fragment>
                                    <div className="mb-4 border rounded border-dark p-3 d-flex flex-column flex-sm-row">
                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <label>{xmlFlightVerified.flight.airline.code} {xmlFlightVerified.flight.flightNumber}</label>
                                            <label>{xmlFlightVerified.departure.airport} - {xmlFlightVerified.arrival.airport}</label>
                                        </div>
                                        <div className="flex-1 d-flex flex-column align-items-end">
                                            <label className="bold text-uppercase">{formatFlightStatus(xmlFlightVerified.flight.flightStatus)}</label>
                                            {(['NoTakeOffInfo', 'Unknown'].includes(xmlFlightVerified.flight.flightStatus)) ? (
                                                <div className="w-100 my-1 d-flex flex-column align-items-center text-primary p-2"
                                                    style={{ background: '#ebebeb' }}
                                                >
                                                    <label> NA </label>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    {(xmlFlightVerified.arrival.actual || xmlFlightVerified.arrival.updated) && (
                                                        <div className={`w-100 my-1 d-flex flex-column align-items-center ${
                                                                xmlFlightVerified.arrival.updated
                                                                    ? 'text-primary'
                                                                    : xmlFlightVerified.arrival.actual ? 'text-success' : ''
                                                            } p-2`}
                                                            style={{ background: '#ebebeb' }}
                                                        >
                                                            <label>
                                                                {xmlFlightVerified.arrival.actual ? 'ACTUAL'
                                                                    : xmlFlightVerified.arrival.updated && 'UPDATE'
                                                                }
                                                            </label>
                                                            <label>
                                                                {`DEP: ${xmlFlightVerified.departure.actual
                                                                    ? xmlFlightVerified.departure.actual.time
                                                                    : xmlFlightVerified.departure.updated
                                                                        ? xmlFlightVerified.departure.updated.time
                                                                        : xmlFlightVerified.departure.scheduled.time} ARR: ${
                                                                xmlFlightVerified.arrival.actual
                                                                    ? xmlFlightVerified.arrival.actual.time
                                                                    : xmlFlightVerified.arrival.updated
                                                                        ? xmlFlightVerified.arrival.updated.time
                                                                        : xmlFlightVerified.arrival.scheduled.time}`
                                                                }
                                                            </label>
                                                        </div>
                                                    )}

                                                    {(xmlFlightVerified.departure.scheduled) && (
                                                        <div className="w-100 my-1 d-flex flex-column align-items-center p-2 border border-dark">
                                                            <label> SCHEDULED </label>
                                                            <label>
                                                                DEP: {xmlFlightVerified.departure.scheduled.time} ARR: {xmlFlightVerified.arrival.scheduled.time}
                                                            </label>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    {(xmlFlightVerified.arrival.actual || xmlFlightVerified.arrival.updated) ? (
                                        <button type="button" className="btn btn-dark btn-block"
                                            onClick={() => {
                                                useVerifyFlight();
                                                setModalVerifyFlight(false);
                                            }}
                                        >
                                            USE UPDATED TIME
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className="mb-4 border rounded border-dark p-3 d-flex">
                                    <div className="flex-1 d-flex flex-column align-items-start">
                                        <label>
                                            {formValues
                                                ? typeof formValues.airline == 'object'
                                                    ? formValues.airline.code
                                                    : formValues.airline
                                                : ''
                                            } {formValues ? formValues.flight : ''}
                                        </label>
                                        <label> NOT FOUND </label>
                                    </div>
                                    <div className="flex-1 d-flex flex-column align-items-end">
                                        <label className="bold text-uppercase">{formatFlightStatus()}</label>
                                        <div className="w-100 d-flex flex-column align-items-center text-primary p-2" style={{ background: '#ebebeb' }}>
                                            <label className="my-2">NA</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {Object.keys(xmlFlightVerified).length === 0 ||
                            xmlFlightVerified.noInfo ? (
                                <button
                                    type="button"
                                    className="btn btn-dark btn-block"
                                    onClick={() => {
                                        useOriginalVerifyFlight();
                                        setModalVerifyFlight(false);
                                    }}
                                >
                                    CANCEL
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        className="btn btn-link border border-dark text-decoration-none text-dark btn-block"
                                        onClick={() => {
                                            useOriginalVerifyFlight();
                                            setModalVerifyFlight(false);
                                        }}
                                    >
                                        {!xmlFlightVerified.arrival.updated &&
                                        !xmlFlightVerified.arrival.actual
                                            ? "SELECT"
                                            : "USE SCHEDULED TIME"}
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn ${
                                            !xmlFlightVerified.arrival.updated &&
                                            !xmlFlightVerified.arrival.actual
                                                ? "btn-dark"
                                                : "btn-primary"
                                        } btn-block`}
                                        onClick={() => {
                                            setModalVerifyFlight(false);
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </LoadMask>
            </div>
        </Modal>
    );
};

const mstp = (state) => ({
    ...state.liveryEdition,
    formValues: state.form.createLivery && state.form.createLivery.values,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(ModalVerify);
