import React, { useState, useEffect } from "react";
import Select, { AsyncCreatable, Async } from "react-select";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Switch from "react-switch";
import isEmpty from "lodash/isEmpty";
import FileUploader from "../FileUploader/FileUploader";
import UnstyledFileUploader from "../UnstyledFileUploader/UnstyledFileUploader";
import { Dropdown, Header } from 'semantic-ui-react'
import "semantic-ui-css/semantic.min.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import cardValidator from 'card-validator';
import 'react-date-range/dist/styles.css'; // main style react-date-range
import 'react-date-range/dist/theme/default.css'; // theme css react-date-range
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"
import app from '../../../../libs/apiClient';
import TimezoneSelect from 'react-timezone-select'
import ReactStars from "react-rating-stars-component";
import IconCheck from "../../../icons/check";
import IconLocation from "../../../icons/locationIcon";
import { IconPlaneAlt } from "../../../icons/planeIcon";
import { RenderNumber } from "./renderReadField";
import { selectStyles } from "../styles";

const { MEDIA_URL, BACKEND_URL } = process.env;

export const renderField = ({
    id,
    disabled,
    input,
    placeholder,
    type,
    cb,
    hidden,
    myChange,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                id={id}
                {...input}
                placeholder={placeholder}
                type={type}
                hidden={hidden}
                disabled={disabled}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                onChange={(data) => {
                    input.onChange(data);
                    if (cb) cb();
                    myChange && myChange(data);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderLabeledField = ({
    id,
    input,
    placeholder,
    type,
    disabled = false,
    meta: { touched, error },
    className,
    inputRef
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <input
                {...input}
                id={id}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                ref={inputRef}
                className={classNames('form-control pt-4 pl-2 text-fluid', {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderLabeledField2 = ({
    id,
    input,
    placeholder,
    type,
    disabled = false,
    meta: { touched, error },
    className,
    inputRef
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <input
                {...input}
                id={id}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                ref={inputRef}
                className={classNames('form-control pt-2 pl-2 text-fluid', {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPhoneNumber = ({
    className,
    placeholder,
    input,
    label,
    type,
    formatTemplate,
    decimalScale,
    meta: { touched, error },
    removePaddingTop = false,
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <NumberFormat
                className={classNames("form-control pl-2", {
                    "is-invalid": invalid,
                    "pt-4": !removePaddingTop,
                })}
                format={formatTemplate}
                value={input.value}
                placeholder={placeholder}
                onValueChange={values => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCreditCard = ({
    className,
    placeholder,
    input,
    meta: { touched, error },
}) => {
    let format = '#### #### #### ####';
    const invalid = touched && error;
    const { card } = cardValidator.number(input.value);

    if (card) {
        format = '';
        const cardLengt = Math.max(...card.lengths);

        for (let i = 1; i <= cardLengt; i++) {
            format = format.concat('#');
            if (card.gaps.includes(i)) format = format.concat(' ');
        }
    }

    return (
        <div className={className}>
            <NumberFormat
                className={classNames('form-control pt-4 pl-2', {
                    'is-invalid': invalid,
                })}
                format={format}
                value={input.value}
                placeholder={placeholder}
                onValueChange={values => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSecurityCode = ({
    className,
    codeSize,
    placeholder,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <NumberFormat
                className={classNames('form-control pt-4 pl-2', {
                    'is-invalid': invalid,
                })}
                format={Array(codeSize).fill('#').join('')}
                value={input.value}
                placeholder={placeholder}
                onValueChange={values => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderTextArea = ({
    input,
    label,
    rows,
    disabled,
    type,
    className = "",
    inputRef,
    meta: { touched, error },
    maxLength = null,
}) => {
    const invalid = touched && error;
    const extraProps = {};

    if (maxLength !== null && maxLength !== undefined) {
        extraProps.maxLength = maxLength;
    }

    return (
        <div className={className}>
            <textarea
                {...input}
                disabled={disabled}
                placeholder={label}
                ref={inputRef}
                style={{ resize: "none" }}
                rows={rows || 3}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                {...extraProps}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderRating = ({
    activeColor="#ffd700",
    className="",
    isHalf= false,
    count=5,
    size=24,
    input,
    meta: { touched, error },
}) => {

    const invalid = touched && error;

    return (
        <div>
            <ReactStars
                count={count}
                onChange={(value) => {
                    input.onChange(value);
                }}
                classNames={className}
                isHalf={isHalf}
                size={size}
                activeColor={activeColor}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
}

export const renderSearchSelect = ({
    input,
    clearable,
    disabled,
    myChange,
    loadOptions,
    valueKey,
    labelKey,
    allKey = false,
    placeholder,
    multi=false,
    className="",
    padding = false,
    inModal = false,
    selectStyles = {},
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <Async
                clearable={clearable}
                disabled={disabled}
                value={input.value}
                multi={multi}
                className={classNames(`form-control select-reset ${className}`, {
                    "is-invalid": invalid,
                    "p-0": !padding
                })}
                onChange={(e) => {
                    input.onChange(e ? !allKey ? e[valueKey] : e : null);
                    myChange && myChange(e);
                }}
                cache={false}
                searchPromptText="Type to search"
                valueKey={valueKey}
                labelKey={labelKey}
                placeholder={placeholder}
                loadOptions={loadOptions}
                style = {inModal ? {
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                } : {}}
                {...selectStyles}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSearchCreateSelect = ({
    input,
    disabled,
    loadOptions,
    valueKey = "value",
    labelKey = "label",
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <AsyncCreatable
                disabled={disabled}
                value={input.value}
                defaultOptions
                className={classNames({
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    input.onChange(e);
                }}
                searchPromptText="Write to search"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
                promptTextCreator={(label) => {
                    return `Create option ${label}`;
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSelectField = ({
    input,
    searchable,
    clearable,
    className = "",
    placeholder,
    disabled,
    options,
    valueKey = "value",
    labelKey = "label",
    myChange,
    padding=false,
    cb,
    meta: { touched, error },
    inModal = false,
    selectStyles = {},
    inputRef,
    isFormControlActive = true
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <Select
                clearable={clearable}
                className={classNames(`${isFormControlActive ? 'form-control': ''} text-fluid p-0`, {
                    "is-invalid": invalid,
                    "p-0": !padding
                })}
                backspaceRemovesValue={false}
                searchable={searchable}
                labelKey={labelKey}
                valueKey={valueKey}
                options={options}
                placeholder={placeholder}
                ref={inputRef}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                    if (cb) {
                        cb();
                    }
                    myChange && myChange(e);
                }}
                value={input.value}
                disabled={disabled}
                style = {inModal ? {
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                } : {}}
                {...selectStyles}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, classNameDiv ="", classNameLabel="", disabled=false, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className={`checkbox ${classNameDiv}`}>
                <label className={`needsclick ${classNameLabel}`} style={{ cursor: "pointer" }}>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        checked={input.value}
                        className={classNames({ 'is-invalid': invalid })}
                        onChange={(e) => { input.onChange(e ? e: null); }}
                    />
                    {classNameDiv.indexOf('c-checkbox') !== -1 && (
                        <span>
                            <IconCheck className="text-white"/>
                        </span>
                    )}
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="d-block invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderCount = ({
    input,
    min,
    max,
    className="",
    disabled = false,
    meta: { touched, error },
}) => {
    useEffect(()=>{
        if(!input.value || input.value.length == 0)
            input.onChange(min ? min.toString() : '1');
    }, [input.value])
    const invalid = touched && error;
    const changeValue = (minus) => {
        let value = parseInt(input.value);
        value = minus ? value - 1 : value + 1;
        if((!max || value <= max) && (!min || value >= min))
            input.onChange(value.toString());
    }
    return (
        <div className={className}>
            <div className="form-control rounded w-100 d-flex align-items-center">
                <button className="border-0 bg-white px-0 px-md-2 m-0"
                    onClick={(e)=>{
                        e.preventDefault();
                        if(!disabled) changeValue(true);
                    }}
                >
                    <h4 className={`mb-0 ${disabled ? 'text-secondary' : 'text-primary'} bold`}>-</h4>
                </button>
                <h5 className="mb-0 flex-1 text-center text-truncate">
                    <RenderNumber value={input.value} />
                </h5>
                <button className="border-0 bg-white px-0 px-md-2 m-0"
                    onClick={(e)=>{
                        e.preventDefault();
                        if(!disabled) changeValue(false);
                    }}
                >
                    <h4 className={`mb-0 ${disabled ? 'text-secondary' : 'text-primary'} bold`}>+</h4>
                </button>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
}

export const renderNumber = ({
    input,
    label,
    type,
    suffix,
    labeled = false,
    formatTemplate,
    placeholder="",
    className="",
    classNameInput="",
    thousandSeparator=true,
    decimalScale,
    disabled = false,
    inputRef,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={className}>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                    'pt-4 pl-2': labeled,
                }, `${classNameInput}`)}
                decimalScale={decimalScale || 0}
                fixedDecimalScale
                value={input.value}
                format={formatTemplate}
                suffix={suffix}
                placeholder={placeholder}
                thousandSeparator={thousandSeparator}
                prefix=""
                ref={inputRef}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCurrency = ({
    input,
    label,
    type,
    decimalScale=2,
    meta: { touched, error },
    allowNegative=true,
    disabled=false,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                placeholder={label}
                allowNegative={allowNegative}
                prefix="$ "
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitch = ({
    input,
    label,
    type,
    className = "",
    meta: { touched, error },
    cb,
    width = 56,
    height = 28,
}) => {
    const invalid = touched && error;
    return (
        <div className={`${className}`}>
            <Switch
                onChange={(value) => {
                    input.onChange(value);
                    if (cb) cb();
                }}
                checked={!!input.value}
                id="normal-switch"
                width={width}
                height={height}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFilePicker = ({
    file,
    category,
    className,
    placeholder,
    input,
    label,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <FileUploader
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                    };

                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    );
};

export const renderImagePicker = ({
    photo,
    className,
    input,
    validateResolution,
    showImage=true,
    useMediaUrl=true,
    alternativeText='',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <FileUploader
                img={photo ? `${useMediaUrl ? MEDIA_URL : BACKEND_URL}${photo}` : null}
                onFileChange={(e, file) => {
                    const dFile = file || e.target.files[0];
                    input.onChange(dFile);
                }}
                showImage={showImage}
                alternativeText={alternativeText}
                validateResolution={validateResolution ? validateResolution : null}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderUnstyledFilePicker = ({
    file,
    category,
    className = "",
    placeholder,
    input,
    label,
    accept = "",
    cb,
    stringBinary = false,
    classNameSubmit,
    classNameLabel,
    classNameContainer,
    defaultButtonType,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <UnstyledFileUploader
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(stringBinary ? { type: file.type, extension: file.name.match(/[^\.][^/.]+$/g), file: reader.result } : reader.result);
                    };

                    if(file)
                        reader.readAsDataURL(file);
                }}
                submit={cb}
                classNameSubmit={classNameSubmit}
                classNameLabel = {classNameLabel}
                classNameContainer={classNameContainer}
                defaultButtonType={defaultButtonType}
                accept={accept}
                label={label}
                invalid={invalid}
                error={error}
            />
            {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    );
};

export const renderDropdownSelection = ({
    options=[],
    selection,
    fluid,
    className = "",
    input,
    multiple,
    clearable,
    scrolling,
    search,
    icon,
    cb,
    labeled,
    disabled,
    placeholder,
    meta: { touched, error },
    customOnChange,
    selectOnBlur=false,
    selectOnNavigation=false,
    wrapSelection=true,
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <Dropdown
                placeholder={placeholder}
                fluid={fluid}
                selection={selection}
                options={options}
                clearable={clearable}
                scrolling={scrolling}
                search={search}
                multiple={multiple}
                labeled={labeled}
                icon={icon}
                value={(input.value) || (multiple ? [] : "")}
                disabled={disabled}
                selectOnBlur={selectOnBlur}
                selectOnNavigation={selectOnNavigation}
                wrapSelection={wrapSelection}
                onChange={
                    (event,data)=>{
                        input.onChange(data.value);
                        customOnChange && customOnChange(data);
                        cb && cb();
                    }
                }
            />
        {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    )
}

export const timezonePicker = ({
    className = "",
    input,
    placeholder = 'Select Timezone...',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <TimezoneSelect
                value={input.value}
                onChange={(offset) => {
                    input.onChange(offset.value);
                }}
                style={{ border: "1px solid #f2a561" }}
            />
            {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    )
}

export class renderDatePicker extends React.Component {
    state = {
        dpFocused: false,
    };

    onFocusChange = ({ focused }) => {
        this.setState({ dpFocused: focused });
    };

    defaultIsDayBlocked = (isOutsideRange) => {
        return !isOutsideRange ? undefined : day => {
            let today = moment().set({ hour: '0', minute: '0', second: '0' });
            if (day.isBefore(today)) return true;
        }
    }

    render() {
        const {
            id,
            cb,
            input,
            className,
            disabled = false,
            monthFormat,
            displayFormat,
            numberOfMonths,
            isDayBlocked,
            isOutsideRange = true,
            placeholder = moment().format('ddd, DD MMMM YYYY'),
            meta: { touched, error },
        } = this.props;
        const invalid = touched && error;

        return (
            <div
                className={classNames(`form-control ${className}`, {
                    'is-invalid': invalid,
                })}
            >
                <SingleDatePicker
                    placeholder={placeholder}
                    date={input.value ? moment(input.value) : null}
                    focused={this.state.dpFocused}
                    disabled={disabled}
                    onDateChange={value => {
                        input.onChange(value);
                        cb && cb(value)
                    }}
                    isDayHighlighted={(day) =>
                        !input.value || !moment(input.value).isSame(moment(), "day")
                            ? day.isSame(moment(), "day")
                            : false
                    }
                    monthFormat={monthFormat}
                    displayFormat={displayFormat}
                    isOutsideRange={isOutsideRange ? undefined : () => false }
                    isDayBlocked={isDayBlocked ? (day)=>isDayBlocked(isOutsideRange, day) : this.defaultIsDayBlocked(isOutsideRange)}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={numberOfMonths}
                    id={id ? id : 'unique'}
                />
                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
}

export class renderPlacesAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.searchOptions = {};
        this.autoCompleteContainerRef = React.createRef();
    }

    state = {
        loading: false,
        mousedownOnSuggestion: false,
        suggestions: [],
    }

    componentDidMount() {
        const { Value, placeId, lat, lng, inputLabel, searchOptions } = this.props;
        if(searchOptions){
            this.searchOptions = Object.assign(this.searchOptions, searchOptions);
        }

        if (lat && lng) this.verifyOnLoad(lat, lng, undefined, inputLabel);

        if(Value){
            app.service('/api/googleMaps').find({ query: { search: Value } }).then(({ predictions, status })=>{
                if(status === 'OK' && predictions.length){
                    const found = predictions.reduce((found,current) =>
                        current.matched_substrings.length > found.matched_substrings.length ? current : found, predictions[0]
                    );
                    if(found.place_id){
                        this.handleSelect(Value, found.place_id);
                    }
                }
            })
        }

        if(placeId)
           this.handleSelect(Value, placeId);
      }

    verifyOnLoad = (lat, lng, msg, label) => {
        // gets place id
        app.service('/api/googleMaps').get('geocode', {
            query: { location: { lat, lng } }
        }).then(({ results: [place], status }) => {
            if (status != 'OK')
                throw Error(status);

            if(place.place_id)
                this.handleSelect(label, place.place_id)
        }).catch(error => {
            console.error(msg, error);
        })
    }

    handleSelect = (address, placeId) => {
        this.geocode(placeId, address);
    }

    geocode = (placeId, label) => {
        const { setDetails, input, pl } = this.props;

        app.service('/api/googleMaps').get('geocode', {
            query: { placeId }
        }).then(async ({ results: [place], status }) => {
            if (status != 'OK') return;

            const { address_components, geometry: { location }, formatted_address, types, place_id } = place;
            const { lat, lng } = location;

            let street_number = {},
                route = {},
                locality = {},
                administrative_area_level_1 = {},
                postal_code = {};

            address_components.forEach(component => {
                if (component.types.includes('street_number'))
                    street_number = component;
                else if (component.types.includes('route'))
                    route = component;
                else if ( isEmpty(locality) && (component.types.includes('locality') || component.types.includes('sublocality')) )
                    locality = component;
                else if ( component.types.includes('administrative_area_level_1') )
                    administrative_area_level_1 = component;
                else if (component.types.includes('postal_code'))
                    postal_code = component;
            });

            if (isEmpty(locality)) {
                const localityComponent = address_components.find(
                    component =>
                        component.types.includes('neighborhood') &&
                        component.types.includes('political')
                );
                if (localityComponent) locality = localityComponent;
            }

            const short_str_no = street_number.short_name || '';
            const short_route = route.short_name || '';
            const short_locality = locality.short_name || '';
            const short_admin_area_lv_1 = administrative_area_level_1.short_name || '';
            const pretty_address = `${short_str_no} ${short_route}, ${short_locality}, ${short_admin_area_lv_1}`;

            let obj = {
                lat,
                lng,
                state: short_admin_area_lv_1,
                city: locality.long_name || '',
                postal_code: postal_code.short_name,
                formatted_address,
                types,
                pretty_address,
                label,
                placeId: placeId,
            }

            let additionalComponents = {};
            if(this.props.additionalAddressInfo){
                additionalComponents = address_components.reduce((acum, current) => {
                    this.props.additionalAddressInfo.forEach(item=>{
                        if(current.types.includes(`${item}`)){
                            acum[item] = current.short_name || '';
                        }
                    })
                    return acum
                }, {})
            }
            if(Object.keys(additionalComponents).length > 0){
                obj = { ...obj, ...additionalComponents}
            }

            const { city, postal_code: zip, state } = obj;
            if((!city || !zip || !state) && obj.types.includes('airport')) {
                // Search for airport in DB using lat and lng;
                const airportsResponse = await app.service('/api/airport').find({ query: { lat, lng } });
                if(airportsResponse && airportsResponse.total > 0){
                    const { data: [airport] } = airportsResponse;
                    if(!city) obj.city = airport.city;
                    if(!zip) obj.postal_code = airport.zip;
                    if(!state) obj.state = airport.state;
                }
            }

            if(place_id && this.props.getPlaceId)
                obj.place_id = place_id

            setDetails( obj, pl );

            input.onChange(this.props.isZip && postal_code.short_name ? postal_code.short_name : (label || formatted_address));
        }).catch(error=>{
            console.log(error);
        })
    }

    handleFromDB = (data) => {
        const { setDetails, input, pl } = this.props;
        setDetails(data,pl);
        input.onChange(data.label);
    }

    loadDirections = async (search) => {
        if(!search.length){
            if(this.state.suggestions.length > 0)
                this.setState({ suggestions: [] });
            return;
        }
        this.setState({ loading: true });
        const suggestions = [];
        if(this.props.aditionalAutocomplete){
            let result = await this.props.aditionalAutocomplete(search);
            if(result && result.length > 0) {
                for(let i = 0; i < result.length; i++)
                    suggestions.push(result[i])
            }
        }
        let { predictions, status } = await app.service('/api/googleMaps').find({
            query: { search, options: this.searchOptions }
        });
        if(status === 'OK'){
            predictions = predictions.sort(
                (a, b) => a.types.includes('airport')
                ? b.types.includes('airport') ? 0 : -1
                : b.types.includes('airport') ? 1 : 0
            )

            for(let i = 0; i < predictions.length ; i++){
                let include = this.props.aditionalAutocomplete
                    ? suggestions.find(item => item.fromDB && predictions[i].description.match(new RegExp(item.code)) != null) == undefined
                    : true;

                if(include){
                    suggestions.push({
                        label: predictions[i].description,
                        value: predictions[i]
                    })
                }
            }
        } else {
            console.error(`error happened when fetching data from Google Maps API \n Status: ${status}`)
        }
        this.setState({ suggestions });
        this.setState({ loading: false });
    }

    setMouseDownOnSuggestion = (mousedownOnSuggestion) => this.setState({ mousedownOnSuggestion });

    render() {
        const {
            input,
            className,
            label,
            id,
            disabled,
            placeholder,
            meta: { touched, error },
            inModal = false,
            hasNoteModal = false,
            noteModal,
        } = this.props;

        const { loading, suggestions, mousedownOnSuggestion } = this.state;

        const invalid = touched && error;

        return (
            <div
                className={classNames(
                    `w-100 p-0 input-highlight ${inModal ? 'm-2 form-control' : 'my-2'}`,
                    { 'is-invalid': invalid, 'mb-4': hasNoteModal && invalid }
                )}
            >
                {inModal && <label>{label}</label>}
                <div ref={this.autoCompleteContainerRef}>
                    <input
                        {...input}
                        placeholder={placeholder}
                        autoComplete="off"
                        className={`input-reset pl-2 mb-1 w-100 ${!inModal ? 'form-control' : ''}`}
                        onChange={(e) => {
                            const { value } = e.target;
                            this.loadDirections(value)
                            input.onChange(value);
                        }}
                        onBlur={() => {
                            if (!suggestions.length) return;

                            if (!mousedownOnSuggestion) {
                                const [item] = suggestions;
                                if(!item.fromDB){
                                    let { value: { place_id }, label } = item;
                                    this.handleSelect(label, place_id);
                                } else {
                                    this.handleFromDB(item);
                                }
                            }

                            this.setState({ suggestions: [] });
                            this.autoCompleteContainerRef.current.className = '';
                        }}
                        onFocus={() => {
                            this.autoCompleteContainerRef.current.className = 'autocomplete-container';
                        }}
                        disabled={disabled}
                    />
                    {loading && (
                        <div className="autocomplete-dropdown-container mt-2">
                            <div>Loading...</div>
                        </div>
                    )}
                    {suggestions.length > 0 && (
                        <div className="autocomplete-dropdown-container mt-2">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    className="p-1 select-option d-flex align-items-center"
                                    key={index}
                                    onMouseLeave={()=> this.setMouseDownOnSuggestion(false)}
                                    onMouseDown={(e)=> {
                                        e.preventDefault();
                                        this.setMouseDownOnSuggestion(true)
                                    }}
                                    onMouseUp={()=> this.setMouseDownOnSuggestion(false)}
                                    onTouchStart={()=> this.setMouseDownOnSuggestion(true)}
                                    onTouchEnd={()=> this.setMouseDownOnSuggestion(false)}
                                    onClick={()=>{
                                        this.setState({ suggestions: [] });
                                        if(!suggestion.fromDB)
                                            this.handleSelect(suggestion.label, suggestion.value.place_id);
                                        else
                                            this.handleFromDB(suggestion);
                                        this.autoCompleteContainerRef.current.className = '';
                                    }}
                                >
                                    <span className="pl-1 pr-2">
                                        {suggestion.fromDB || suggestion.value.types.includes('airport')
                                            ? <IconPlaneAlt className="smallIcon" />
                                            : <IconLocation className="smallIcon"/>
                                        }
                                    </span>
                                    <span className="flex-1"> {suggestion.label} </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {hasNoteModal ? noteModal : null}
                {invalid && (
                    <div className="invalid-feedback"> {error} </div>
                )}
            </div>
        );
    }
}

export const CalendarRange = ({
    input,
    className="",
    meta: { touched, error },
    changeDate,
    minDate=new Date(moment().year(),0,1),
    maxDate=new Date(moment().year(),11,31),
    dates = [],
    range = false,
    multiple = false,
}) => {

    const invalid = touched && error;

    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid, })}>
            <Calendar
                value={dates}
                onChange={(date) => {
                    input.onChange(date);
                    if(changeDate) changeDate(date);
                }}
                className="red"
                minDate={minDate}
                maxDate={maxDate}
                range={range}
                multiple={multiple}
            />
            {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    )
}

export const DatePickerAlt = ({
    input,
    className="",
    meta: { touched, error },
    changeDate,
    minDate,
    maxDate,
    range = false,
    multiple = false,
    format="MM/DD/YYYY",
    placeholder = moment().format('ddd, DD MMMM YYYY'),
    ...restProps
}) => {

    const invalid = touched && error;

    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid, })}>
            <DatePicker
                value={input.value}
                onChange={(date) => {
                    input.onChange(date.format());
                    if(changeDate) changeDate(date);
                }}
                className="red"
                inputClass="form-control"
                containerClassName="w-100"
                placeholder={placeholder}
                minDate={minDate}
                maxDate={maxDate}
                range={range}
                multiple={multiple}
                format={format}
                {...restProps}
            />
            {invalid && <div className="invalid-feedback"> {error} </div>}
        </div>
    )
}

export const RenderField = {
    renderField,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderDropdownSelection,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
};
