const {FLEETIO_ACCOUNT_TOKEN, FLEETIO_AUTH_TOKEN} = process.env;

export const loadMainVehicles = async (search) => {
    const url = 'https://secure.fleetio.com/api/v1/vehicles?paginate=false';

    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': FLEETIO_AUTH_TOKEN,
                'Account-Token': FLEETIO_ACCOUNT_TOKEN,
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Error calling the API');
        }
       
        const data = await response.json();

        let filteredData = data;
        if (search) {
            const searchLower = search.toLowerCase();
            filteredData = data.filter(vehicle =>
                vehicle.name.toLowerCase().includes(searchLower)
            );
        }

        const formattedData = filteredData.map(vehicle => ({
            name: vehicle.name,
            label: vehicle.name,
            id:vehicle.id,
            type: vehicle.vehicle_type_name
        }));

        formattedData.sort((a, b) => a.name.localeCompare(b.name));

        formattedData.unshift({
            name: 'None',
            label: 'None',
            noVehicle: true
        });

        return { options: formattedData };

    } catch (error) {
        console.error('Error loading vehicles:', error);
        return { options: [] };
    }
};

export const loadFleetDrivers = async (search) => {
    let allContacts = []; 
    let nextCursor = ''; 
    const baseUrl = `https://secure.fleetio.com/api/v2/contacts?per_page=100`;

    try {
        while (nextCursor !== null) {
            const url = nextCursor
                ? `${baseUrl}&start_cursor=${nextCursor}`
                : baseUrl;

            const response = await fetch(url, {
                headers: {
                    'Authorization': FLEETIO_AUTH_TOKEN,
                    'Account-Token': FLEETIO_ACCOUNT_TOKEN,
                    'Accept': 'application/json',
                    'X-Api-Version': '2024-06-30'
                }
            });

            if (!response.ok) {
                throw new Error('Error calling the API');
            }

            const data = await response.json();
            allContacts = allContacts.concat(data.records); 
            nextCursor = data.next_cursor;
        }
      
        let filteredData = allContacts.filter(driver => driver.job_title === 'Chauffeur');
        if (search) {
            const searchLower = search.toLowerCase();
            filteredData = filteredData.filter(driver =>
                driver.name.toLowerCase().includes(searchLower)
            );
        }

        const formattedData = filteredData.map(driver => ({
            name: driver.name,
            label: driver.name,
            id:driver.id,
            employeeNum: driver.employee_number,
            groupName: driver.group_name
            
        }));
        formattedData.sort((a, b) => a.name.localeCompare(b.name));
        formattedData.unshift({
            name: 'None',
            label: 'None',
            noVehicle: true
        });

        return { options: formattedData };

    } catch (error) {
        console.error('Error loading drivers:', error);
        return { options: [] };
    }
}
