import { createActions, handleActions } from "redux-actions";
import { goBack, push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import app from "../../libs/apiClient";
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
// import airportCodes from '../../common/utility/airports.json';
import airlineCodes from '../../common/utility/airlines.json';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { liveryBaseUrl, S_UIUD, S_AUTHEN_KEYS } from '../../common/utility/constants';
import swal from "sweetalert2";
import { errorHandler, liveryVehicleType } from '../../common/utility/constants';
import { reservationsAirline, reservationsAirlineBook, reservationsAirlineSingleTripSearch } from "../../routesConfiguration/paths";
import { is } from "immutable";

const service = app.service('api/crew');

// Actions
const LOADING = "LOADING_LIVERY_EDITION";
const LOADING_LOGS_LIVERY_EDITION = "LOADING_LOGS_LIVERY_EDITION";
const LIST_LOGS_LIVERY_EDITION = "LIST_LOGS_LIVERY_EDITION";
const PAGE_LOGS_LIVERY_EDITION = "PAGE_LOGS_LIVERY_EDITION";
const SET_VIEW = "SET_VIEW_LIVERY_EDITION";
const SET_FLIGHTS = "SET_FLIGHTS_LIVERY_EDITION";
const SET_INPUT_DATA = "SET_INPUT_DATA_LIVERY_EDITION";
const SET_SINGLE_DETAIL = "SET_SINGLE_DETAIL_LIVERY_EDITION";
const SET_AIRLINE_CODES = "SET_AIRLINE_CODES_LIVERY_EDITION";
const SET_AIRPORT_CODES = "SET_AIRPORT_CODES_LIVERY_EDITION";
const SET_IS_AIRPORT = "SET_IS_AIRPORT";
const FROM = "FROM_LIVERY_EDITION";
const TO = "TO_LIVERY_EDITION";
const SET_LOADING_VERIFIER = "SET_LOADING_VERIFIER_LIVERY_EDITION";
const SET_FLIGHT_VERIFIED = "SET_FLIGHT_VERIFIED_LIVERY_EDITION";
const MODAL_COMPANY_SELECT = "MODAL_COMPANY_SELECT";
const MODAL_LOGS = "MODAL_LOGS_LIVERY_EDITION";

const loading = (loading = true) => ({
    type: LOADING,
    loading,
});

export const encrypt = (sParamData) => {
    return {
        sUIUD: S_UIUD,
        sParamData,
        sAuthenKeys: S_AUTHEN_KEYS,
    };
};

export const findAirport = (data) => (dispatch, getStore) => {
    const { airportCodes } = getStore().liveryEdition;
    let foundAirport;
    if (data._id) {
        foundAirport = airportCodes.find(
            (item) => JSON.stringify(item._id) === JSON.stringify(data._id)
        );
    } else {
        const street = `${data.street_number ? data.street_number : ''}${data.route ? ` ${data.route}` : ''}`.trim().toLowerCase().match(/\w+/g);
        foundAirport = airportCodes.find((a) => {
            const airportStreet = a.street.trim().toLowerCase().match(/\w+/g);
            const dataZip = !isNaN(data.postal_code) ? parseInt(data.postal_code) : data.postal_code;
            return isEqual(a.zip, dataZip) && isEqual(airportStreet, street);
        });
    }
    return foundAirport;
};

export const getFlights = data => async(dispatch, getStore) => {
    dispatch(loading(true));
    const { airlineCodes } = getStore().liveryEdition;
    const airlineCode = data.airline instanceof Object ? data.airline : airlineCodes.find( item =>
        item.code.trim().toLowerCase() == data.airline.trim().toLowerCase() ||
        item._id.trim() == data.airline.trim() ||
        item.label.trim() == data.airline.trim()
    );
    try {
        if(!airlineCode){
            if(!airportCode){
                Swal.fire({
                    type: "error",
                    text: "UA cannot be found at IAD"
                })
            } else {
                Swal.fire({
                    type: "error",
                    text: "No matching airline code. Please use 2-letter IATA code."
                });
            }
            dispatch( loading(false) );
        } else {
            const date = moment(data.date).format('l');

            const response = await service.find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`-1[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${date}[[ENCRYPT]]${date}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]`),
                }
            })
            const flights = await service.find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0]
                }
            })
            console.log(flights);
            const ownFlights = flights.Table.filter((item) => {
                const arrayPick = item.PickupAirportDesc.split(/\s+/g);
                const arrayDrop = item.DropoffAirportDesc.split(/\s+/g);
                if (
                    ((item.FlightNbr &&
                        item.FlightNbr.length &&
                        item.FlightNbr === data.flightNumber) ||
                        arrayPick[arrayPick.length - 1] === data.flightNumber ||
                        arrayDrop[arrayDrop.length - 1] ===
                            data.flightNumber) &&
                    ((["CX", "CXC"].includes(airlineCode.code) &&
                        (arrayPick[arrayPick.length - 2].match(new RegExp(`^CX`, 'g')) !== null ||
                            arrayDrop[arrayDrop.length - 2].match(new RegExp(`^CX`, 'g')) !== null)) ||
                        arrayPick[arrayPick.length - 2] === airlineCode.code ||
                        arrayDrop[arrayDrop.length - 2] === airlineCode.code ||
                        airlineCode.code === "OO")
                )
                    return true;
                else
                    return false;
            });
            const noDuplicatedData = ownFlights;
            const finalData = [];
            for(let index = 0; index < noDuplicatedData.length; index++){
                const paxCount = noDuplicatedData[index].Passenger_Name.match(/(\+\d+)/g);
                finalData.push(Object.assign(noDuplicatedData[index], {Numofpassengers: paxCount ? parseInt(paxCount[0]) + 1 : 1 }))
            }
            if(finalData && finalData.length){
                dispatch(setFlights(finalData));
                dispatch(setInputData({ airlineCode, flightNumber: data.flightNumber}));
                if(finalData.length > 1){
                    dispatch(setView(2));
                    dispatch( loading(false) );
                }else {
                    dispatch(getSingleDetails(finalData[0]))
                }
            } else {
                Swal.fire({
                    type: "error",
                    text: "No matching trip found for that date, would you like to add a new trip?",
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true,
                    confirmButtonColor: '#D50032',
                }).then(async ({ value })=>{
                    if(value){
                        let obj = { airline: airlineCode, flight: data.flightNumber, date: data.date };
                        dispatch(initializeForm('createLivery', obj));
                        dispatch(setModalCompanySelect(true));
                    }
                })
                dispatch( loading(false) );
            }
        }
    } catch (error){
        dispatch(setView(1));
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
};

export const getFlightById = data => async(dispatch, getStore) => {
    dispatch(loading(true));
    try {
        const details = await getLiveryDetails(data.liveryNo);

        if(details && details.Table && details.Table.length > 0)
            details.Table = details.Table.filter(trip => trip.OwnerName == "Shuttle");

        if(details && details.Table && details.Table.length > 0){
            let liveryData = details.Table[0];
            let reservationDateTime = moment(liveryData.ReservationDateTime, 'MMM DD YYYY hh:mm A');
            let PUDate = reservationDateTime.format('MM/DD/YYYY');
            let PUTime = reservationDateTime.format('HH:mm');
            const response = await service.find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`${liveryData.CustomerID}[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${PUDate}[[ENCRYPT]]${PUDate}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]${data.liveryNo}[[ENCRYPT]]`),
                }
            })
            const flights = await service.find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0]
                }
            });

            const [company] = await app.service("/api/airline").find({
                query: {
                    $paginate: false,
                    liveryId:
                        liveryData.CustomerID === "11572"
                            ? "10319"
                            : liveryData.CustomerID,
                },
            });

            let airlineCode;
            if(flights && flights.Table && flights.Table.length > 0){
                liveryData.PickupDesc = flights.Table[0].PickupDesc;
                liveryData.DropoffDesc = flights.Table[0].DropoffDesc;
                const { airportCodes, airlineCodes } = getStore().liveryEdition;
                if(!isEmpty(flights.Table[0].Airline)){ // From airport
                    let airlineCodeTable = flights.Table[0].Airline.trim().split(/\s+/);
                    liveryData.airport = !isEmpty(airlineCodeTable) ? airlineCodeTable[0] : undefined;
                    liveryData.airline = !isEmpty(airlineCodeTable) && airlineCodeTable.length === 2 ? airlineCodeTable[1] : airlineCodeTable[0];
                    liveryData.flightNumber = flights.Table[0].FlightNbr;
                } else { // to airport
                    const arrayDrop = flights.Table[0].DropoffAirportDesc.split(/\s+/g);
                    liveryData.airport = arrayDrop[arrayDrop.length - 3].match(/\w+/g);
                    liveryData.airline = arrayDrop[arrayDrop.length - 2];
                    liveryData.flightNumber = arrayDrop[arrayDrop.length - 1];
                    if(liveryData.airport && liveryData.airport.length > 0)
                        liveryData.airport = liveryData.airport[0]
                }

                if(liveryData.airline && liveryData.airline.match(/^CXC/g) != null)
                    liveryData.airline = "CX";

                let airportCode = liveryData.airport ? airportCodes.find( item =>
                    item.code.trim().toLowerCase() == liveryData.airport.trim().toLowerCase() ||
                    item.label.trim().toLowerCase() == liveryData.airport.trim().toLowerCase()
                ) : undefined;

                if(!airportCode)
                    airportCode = { code: liveryData.airport };

                airlineCode = liveryData.airline ? airlineCodes.find((item) =>
                    item.code.trim().toLowerCase() == liveryData.airline.trim().toLowerCase() ||
                    item.label.trim().toLowerCase() == liveryData.airline.trim().toLowerCase()
                ) : undefined;

                liveryData.airline = airlineCode ? airlineCode : { code: liveryData.airline };

                if(!airlineCode)
                    airlineCode = { code: liveryData.airline };

                dispatch(setFlights(flights.Table));
                dispatch(setInputData({ flightNumber: liveryData.flightNumber, airportCode, airlineCode: company }));
            } else {
                let VehicleType = liveryVehicleType.find(item=> item.id == parseInt(liveryData.VehicleTypeID));
                if(VehicleType) VehicleType = VehicleType.label;
                dispatch(setFlights([{
                    ...liveryData, TripID: data.liveryNo, PUDate , PUTime, VehicleType,
                }]));
            }
      
            const resultPickUp = await getPlaceFromGoogleMaps(liveryData.PickupDesc);
            const resultDropOff = await getPlaceFromGoogleMaps(liveryData.DropoffDesc);
            const pickUp = resultPickUp ? {
                ...(resultPickUp.geometry && resultPickUp.geometry.location ? resultPickUp.geometry.location : {}),  
                label: resultPickUp.label, 
                place_id: resultPickUp.place_id
              } : undefined;
              
              const dropOff = resultDropOff ? {
                ...(resultDropOff.geometry && resultDropOff.geometry.location ? resultDropOff.geometry.location : {}),
                label: resultDropOff.label, 
                place_id: resultDropOff.place_id
              } : undefined;
              

            const liveryDBData = await app.service('/api/livery').get(data.liveryNo);
        
            const getLiveryAdress = (data, index)=>{
                let isAdress
                let adress
                if (!data.PUDOList[index].LandMark){
                    isAdress = true
                    adress = data.PUDOList[index].Street
                }else{
                    isAdress = false
                    adress = data.PUDOList[index].LandMark
                }
                return {adress, isAdress}
            }

            const {adress: puLiveryAdress, isAdress: puIsAdress} = getLiveryAdress(liveryData,'0')
            const {adress: doLiveryAdress, isAdress: doIsAdress} = getLiveryAdress(liveryData,'1')

            const normalizeAddress = (address) => {
                if (!address) return '';
                return address
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, ' ')
                    .replace(/[^\w\s]/g, ''); 
            };

            const compareAdresses = ({ isAdress, dbDescription, dbFormatedAdress, liveriLandmark }) => {
                const normalizedLiveriLandmark = normalizeAddress(liveriLandmark);

                if (isAdress) {
                    const normalizedDbDescription = dbDescription ? normalizeAddress(dbDescription) : null;
                    const normalizedDbFormatedAdress = normalizeAddress(dbFormatedAdress);
            
                    if (normalizedDbDescription === null) {
                        return normalizedDbFormatedAdress === normalizedLiveriLandmark;
                    } else {
                        return normalizedDbDescription === normalizedLiveriLandmark;
                    }
                } else {
                    const normalizedDbFormatedAdress = normalizeAddress(dbFormatedAdress);
                    return normalizedDbFormatedAdress === normalizedLiveriLandmark;
                }
            };
            

            let isSamePuLocation;
            let isSameDoLocation;
            
            if (liveryDBData) {
                if (liveryDBData.puDescription) {
                    isSamePuLocation = compareAdresses({
                        isAdress: puIsAdress, 
                        dbDescription: liveryDBData.puDescription, 
                        dbFormatedAdress: liveryDBData.puFormattedAdress, 
                        liveriLandmark: puLiveryAdress
                    });
                } else {
                    isSamePuLocation = compareAdresses({
                        isAdress: puIsAdress, 
                        dbFormatedAdress: liveryDBData.puFormattedAdress, 
                        liveriLandmark: puLiveryAdress
                    });
                }
            
                if (liveryDBData.doDescription) {
                    isSameDoLocation = compareAdresses({
                        isAdress: doIsAdress, 
                        dbDescription: liveryDBData.doDescription, 
                        dbFormatedAdress: liveryDBData.poFormattedAdress, 
                        liveriLandmark: doLiveryAdress
                    });
                } else {
                    isSameDoLocation = compareAdresses({
                        isAdress: doIsAdress, 
                        dbFormatedAdress: liveryDBData.poFormattedAdress, 
                        liveriLandmark: doLiveryAdress
                    });
                }
            }
            
            if (liveryData.FarmStatus === "2" && liveryData.ChauffeurID) {
                const chauffeur = liveryData.ChauffeurID.match(/((\s+)?[a-zA-Z]+)+|(\d+)/g);
                if (chauffeur && chauffeur.length > 0)
                    liveryData.ChauffeurName = chauffeur[0];
                if (!liveryData.ChauffeurPhone && chauffeur && chauffeur.length > 1)
                    liveryData.ChauffeurPhone = chauffeur[1];
            }

            const isPickupFromLivery = resultPickUp ? resultPickUp.label : liveryData.PUDOList.find(item => item.PUDOType == 'Pickup').LandMark
            const isDropoffFromLivery = resultDropOff ? resultDropOff.label : liveryData.PUDOList.find(item => item.PUDOType == 'Dropoff').LandMark
            const isPickupFromBBC = liveryDBData ? (liveryDBData.puDescription || liveryDBData.puFormattedAdress) : null
            const isDropoffFromBBC = liveryDBData ? (liveryDBData.doDescription || liveryDBData.poFormattedAdress) : null;

            dispatch(setSingleDetail({
                ...liveryData,
                PUDate,
                PUTime,
                TripID: data.liveryNo,
                location: { pickUp, dropOff },
                liveryDBData
            }));

            dispatch(setView(3))
            await dispatch(initializePickDropForm());
            dispatch(initializeForm('createLivery',{
                TripID: data.liveryNo,
                date: PUDate,
                time: PUTime,
                pax: liveryData.NumOfPassengers,
                customer_po: details.Table[0].TripNote && details.Table[0].TripNote.split("***")[1] ? details.Table[0].TripNote.split("***")[1] : "",
                pickUp:  isSamePuLocation ? isPickupFromBBC : isPickupFromLivery,
                dropOff: isSameDoLocation ? isDropoffFromBBC : isDropoffFromLivery,
                is_aoa: details.Table[0]["Customer PO#"] ? details.Table[0]["Customer PO#"].match(/-AOA$/g) != null : false,
                is_cgo: details.Table[0]["Customer PO#"] ? details.Table[0]["Customer PO#"].match(/-CGO$/g) != null : false,
                airline: airlineCode,
                flight: liveryData.flightNumber,
            }));
            dispatch( loading(false) );

        } else {
            Swal.fire({
                type: "error",
                text: "No matching trip found for that date, would you like to add a new trip?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true,
                confirmButtonColor: '#D50032',
            }).then(({ value })=>{
                if(value)
                    dispatch(setModalCompanySelect(true));
            })
            dispatch( loading(false) );
        }
    } catch (error){
        dispatch(setView(1));
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
};

export const getLiveryDetails = async (TripID, liveryId) => {
    const tokenize = await service.find({
        query: {
            path: "getTokenizedParams",
            body: encrypt(`${TripID}[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]${liveryId ? liveryId : '-1'}[[ENCRYPT]]-1[[ENCRYPT]]Customer[[ENCRYPT]]`)
        }
    })
    return service.find({
        query: {
            path: "GetReservationDetailForChauffeursEN",
            body: tokenize.Table[0]
        }
    })
}

export const getPlaceFromGoogleMaps = async (address) => {
    let resultData;
    const { predictions, status } = await app.service('/api/googleMaps').find({ query: { search: address, } });
    if(status === 'OK'){
        const [{place_id, description}] = predictions;
        const {results: [result]} = await app.service('/api/googleMaps').get('geocode', { query: { placeId: place_id }});
        resultData = { ...result, label: description, place_id };
    }
    return resultData;
};

export const getSingleDetails = (data) => async (dispatch, getStore) => {
    dispatch(loading(true));
    try {
        let liveryData = {};
        if (!isEmpty(data.Airline)) {
            // From airport
            const airlineCode = data.Airline.trim().split(/\s+/);
            liveryData.airport = !isEmpty(airlineCode) ? airlineCode[0] : undefined;
            liveryData.airline = !isEmpty(airlineCode) && airlineCode.length === 2 ? airlineCode[1] : airlineCode[0];
        } else {
            // to airport
            const arrayDrop = data.DropoffAirportDesc.split(/\s+/g);
            liveryData.airport = arrayDrop[arrayDrop.length - 3].match(/\w+/g);
            liveryData.airline = arrayDrop[arrayDrop.length - 2];
            if (liveryData.airport && liveryData.airport.length > 0)
                liveryData.airport = liveryData.airport[0];
        }

        if(liveryData.airline && liveryData.airline.match(/^CXC/g) != null)
            liveryData.airline = "CX";

        if (!isEmpty(data.FlightNbr))
            liveryData.flight = data.FlightNbr;

        const { airportCodes, airlineCodes, inputData } = getStore().liveryEdition;

        let airportCode = liveryData.airport
            ? airportCodes.find(
                  (item) =>
                      item.code.trim().toLowerCase() ===
                          liveryData.airport.toLowerCase() ||
                      item.label.trim() === liveryData.airport
              )
            : undefined;
        const airline = liveryData.airline
            ? airlineCodes.find(
                  (item) =>
                      item.code.trim().toLowerCase() ===
                          liveryData.airline.trim().toLowerCase() ||
                      item.label.trim().toLowerCase() ===
                          liveryData.airline.trim().toLowerCase()
              )
            : undefined;
        liveryData.airline = airline ? airline : { code: liveryData.airline };

        if (!airportCode)
            airportCode = { code: liveryData.airport };

        dispatch(setInputData({ ...inputData, airportCode }));

        const { airlineCode, flightNumber } = getStore().liveryEdition.inputData;
        const result = await getLiveryDetails(data.TripID, airlineCode.liveryId);
        if (result) {
            if (flightNumber)
                result.Table[0].flightNumber = flightNumber;

            const resultPickUp = await getPlaceFromGoogleMaps(data.PickupDesc);
            const resultDropOff = await getPlaceFromGoogleMaps(data.DropoffDesc);
            const pickUp = resultPickUp ? {
                ...(resultPickUp.geometry && resultPickUp.geometry.location ? resultPickUp.geometry.location : {}),  
                label: resultPickUp.label, 
                place_id: resultPickUp.place_id
              } : undefined;
              
              const dropOff = resultDropOff ? {
                ...(resultDropOff.geometry && resultDropOff.geometry.location ? resultDropOff.geometry.location : {}),
                label: resultDropOff.label, 
                place_id: resultDropOff.place_id
              } : undefined;

            const liveryDBData = await app.service("/api/livery").get(data.TripID);

            const reservationDateTime = moment(result.Table[0].ReservationDateTime, "MMM DD YYYY hh:mm A");
            const PUDate = reservationDateTime.format("MM/DD/YYYY");
            const PUTime = reservationDateTime.format("HH:mm");
            if (liveryData.FarmStatus === "2" && liveryData.ChauffeurID) {
                const chauffeur = liveryData.ChauffeurID.match(/((\s+)?[a-zA-Z]+)+|(\d+)/g);
                if (chauffeur && chauffeur.length > 0)
                    liveryData.ChauffeurName = chauffeur[0];
                if (!liveryData.ChauffeurPhone && chauffeur && chauffeur.length > 1)
                    liveryData.ChauffeurPhone = chauffeur[1];
            }
            dispatch(
                setSingleDetail({
                    ...liveryData,
                    ...result.Table[0],
                    TripID: data.TripID,
                    PUDate,
                    PUTime,
                    location: { pickUp, dropOff },
                    liveryDBData,
                })
            );
            dispatch(setView(3));
            await dispatch(initializePickDropForm());
            dispatch(
                initializeForm("createLivery", {
                    TripID: data.TripID,
                    date: PUDate,
                    time: PUTime,
                    pax: result.Table[0].NumOfPassengers,
                    customer_po: result.Table[0].TripNote && result.Table[0].TripNote.split("***")[1] ? result.Table[0].TripNote.split("***")[1] : "",
                    pickUp: resultPickUp ? resultPickUp.label : result.Table[0].PUDOList.find(item => item.PUDOType == 'Pickup').LandMark,
                    dropOff: resultDropOff ? resultDropOff.label : result.Table[0].PUDOList.find(item => item.PUDOType == 'Dropoff').LandMark,
                    airline,
                    flight: flightNumber,
                    is_aoa: result.Table[0]["Customer PO#"] ? result.Table[0]["Customer PO#"].match(/-AOA$/g) != null : false,
                    is_cgo: result.Table[0]["Customer PO#"] ? result.Table[0]["Customer PO#"].match(/-CGO$/g) != null : false,
                })
            );
            dispatch(loading(false));
        } else {
            dispatch(errorHandler({ message: "empty data" }));
            dispatch(loading(false));
        }
    } catch (error) {
        dispatch(setView(1));
        dispatch(errorHandler(error));
        dispatch(loading(false));
    }
};

export const getAirlineCodes = () => async (dispatch) => {
    const airlineCodes = await app.service("/api/airline").find({ query: { $paginate: false, $populate: ['sharedCodes'] } });
    for(let index = 0; index < airlineCodes.length; index++){
        airlineCodes[index].label = airlineCodes[index].code + " - " + airlineCodes[index].company;
    }
    dispatch(setAirlineCodes(airlineCodes));
};

export const getAirportCodes = () => async (dispatch) => {
    const airportCodes = await app.service('/api/airport').find({ query: { $paginate: false } });
    for(let index = 0; index < airportCodes.length; index++){
        airportCodes[index].label = airportCodes[index].code + " - " + airportCodes[index].name;
    }
    dispatch(setAirportCodes(airportCodes));
}

export const createLiveryTrip = (data) => (dispatch, getStore) => {
    dispatch(loading(true));
    const { from, to, airlineCodes } = getStore().liveryEdition;

    data.company = JSON.parse(localStorage.getItem('company_airline_livery_create'));
    if(!data.company){
        swal.fire({
            type: "error",
            title: "ERROR",
            text: "Company not found"
        });
    } else {

        if(!(data.airline instanceof Object))
            data.airline = airlineCodes.find( item =>
                item.code.trim().toLowerCase() == data.airline.trim().toLowerCase() ||
                item._id.trim() == data.airline.trim() ||
                item.label.trim() == data.airline.trim()
            );

        if(data.flightVerified){
            const toAirport = dispatch(findAirport(to));
            let formDateTime = moment(data.date).set({ 'hour': data.time.hour, 'minutes': data.time.minutes });
            let oldDateTime = moment(`${data.flightVerified.date}`).set({ 'hour': data.flightVerified.time.hour, 'minutes': data.flightVerified.time.minutes });
            if(!formDateTime.isSame(oldDateTime) || !toAirport || (data.flightVerified.flightViewData.airportCode != toAirport.code) ||
                (data.flightVerified.airline.code != data.airline.code)){
                    delete data.flightVerified;
            }
        }
        console.log({ ...data, from, to });
        app.service('/api/livery').create({ ...data, from, to }).then(response=>{
            localStorage.removeItem('company_airline_livery_create');
            Swal.fire({
                type: 'success',
                title: "Success",
                text: "Trip Created"
            }).then(()=>{
                dispatch(push(reservationsAirline));
            });
        }).catch(error=>{
            const { data } = error;
            if(data && data.isBefore){
                Swal.fire({
                    type: "warning",
                    html: `<div>
                        <h5>
                            The pickup time is within 60 minutes of the flight departure time. Would you like to continue and add this trip?
                        </h5>
                    </div>`,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#D60132',
                    cancelButtonColor: '#6C757D',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(({ dismiss, value })=>{
                    if(!dismiss || dismiss != 'overlay'){
                        dispatch(loading(true));
                        let confirmCreate = dismiss && dismiss == 'cancel' ? false : value;
                        app.service('/api/livery').create({ ...data.data, confirmCreate }).then(response=>{
                            console.log(response);
                            if(response && response.liveryId){
                                Swal.fire({
                                    type: "success",
                                    title: "success",
                                    text: "Trip Created"
                                }).then(()=>{
                                    dispatch(push(reservationsAirlineSingleTripSearch))
                                });
                            } else if(response && response.isAlert){
                                dispatch(change('createLivery', 'flightVerified', {
                                    flightViewData: data.flightViewData, airline: data.data.airline, date: data.data.date, time: data.data.time,
                                    logId: response._id,
                                }));
                            }
                        }).catch(error=>{
                            dispatch(errorHandler(error));
                        }).finally(()=>{
                            dispatch(loading(false));
                        });
                    }
                })
            } else {
                dispatch(errorHandler(error));
            }
        }).finally(()=>{
            dispatch(loading(false));
        })
    }
}

export const cancelTripLivery = (cancelReason, callback) => (dispatch, getStore) => {
    dispatch(loading(true));
    const { singleDetail, flights, inputData, from, to } = getStore().liveryEdition;
    const data = flights.find(item => item.TripID == singleDetail.TripID);
    const successMessage = () => Swal.fire({
        type: "success",
        title: "SUCCESS",
        text: "The trip has been canceled",
    }).then(()=>{
        if (singleDetail.FarmStatus === "2" && ["Dispatched", "On The Way", "Arrived"].includes(singleDetail.TripStatusDesc)) {
            let chauffeur = singleDetail.ChauffeurID.match(/((\s+)?[a-zA-Z]+)+|(\d+)/g);
            if (chauffeur == null) {
                chauffeur = data.FODriverID.match(/((\s+)?[a-zA-Z]+)+|(\d+)/g);
            }
            let driverName = chauffeur && chauffeur.length > 0 ? chauffeur[0] : (singleDetail.ChauffeurName || "F/O");
            const driverPhone = chauffeur && chauffeur.length > 0 ? chauffeur[1] : (singleDetail.DriverPhone || "NA");
            if (['TBD', 'F/O'].includes(driverName) && data.FOCustCode) {
                driverName = data.FOCustCode;
            }
            let FoCancelledNotificationMsg = "This trip is farmed out"
            FoCancelledNotificationMsg += ` to ${driverName} ${driverPhone}.`;
            FoCancelledNotificationMsg += " Please make sure to call and notify them of the cancelation.";
            Swal.fire({
                type: "warning",
                title: "F/O Cancellation!",
                text: FoCancelledNotificationMsg,
            }).then(() => {
                callback();
            })
        } else {
            callback();
        }
    })
    app.service('/api/livery').patch(singleDetail.TripID, { action: 'cancel', from, to, singleDetail, inputData, additionalData: data, cancelReason }).then(response=>{
        console.log(response);
        successMessage();
    }).catch(error=>{
        if(error.data && error.data.status == 0){
            Swal.fire({
                title: "Choose a cancellation method",
                text: `Cancellation Policy - ${error.data.data.cxl_policy} mins`,
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "STANDARD",
                cancelButtonText: "LATE CANCEL",
                confirmButtonColor: "#545b62",
                cancelButtonColor: "#D50032",
                reverseButtons: true,
            }).then(result=>{
                let action = result.dismiss && result.dismiss == 'cancel' ? 'lateCancel' : result.value ? 'cancel' : undefined;
                if(action){
                    dispatch(loading(true));
                    app.service('/api/livery').patch(singleDetail.TripID, { action, singleDetail, additionalData: data, ...error.data.data, confirm: true }).then(response=>{
                        console.log(response);
                        successMessage();
                    }).catch(error => {
                        dispatch(errorHandler(error));
                    }).finally(()=>{
                        dispatch(loading(false));
                    });
                }
            });
        } else {
            dispatch(errorHandler(error));
        }
    }).finally(()=>{
        dispatch(loading(false));
    })
}

export const updateTripLivery = (data, callback) => (dispatch, getStore) => {
    dispatch(loading(true));
    const { singleDetail, inputData, from, to } = getStore().liveryEdition;

    data.from = from;
    data.to = to;
    data.singleDetail = singleDetail;
    data.inputData = inputData;

    data.date = moment(data.date).format('MM/DD/YYYY');
    if(typeof data.time == 'object')
        data.time = `${data.time.hour}:${data.time.minutes}`;

    app.service('/api/livery').update(singleDetail.TripID, data).then(response=>{
        console.log(response);
        Swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "Trip updated"
        }).then(()=>{
            callback();
        })
    }).catch( error => {
        dispatch(errorHandler(error));
    }).finally(()=>{
        dispatch(loading(false));
    })
}

export const initializePickDropForm = () =>  async (dispatch, getStore) => {
    const { singleDetail } = getStore().liveryEdition;
    const { location: singleLocation, PUDOList } = singleDetail;
    const query1 = singleLocation.pickUp
        ? singleLocation.pickUp.place_id ? { placeId: singleLocation.pickUp.place_id } : { location: singleLocation.pickUp }
        : { address: PUDOList.find(item => item.PUDOType == 'Pickup').LandMark };
    const query2 = singleLocation.dropOff
        ? singleLocation.dropOff.place_id ? { placeId: singleLocation.dropOff.place_id } : { location: singleLocation.dropOff }
        : { address: PUDOList.find(item => item.PUDOType == 'Dropoff').LandMark };

    if(singleLocation){
        await Promise.all([
            app.service('/api/googleMaps').get('geocode', { query: query1 }),
            app.service('/api/googleMaps').get('geocode', { query: query2 })
        ]).then((result) => {
            let pl = ['from', 'to'];
            for(let i = 0; i < result.length; i++){

                let { results: [place], status } = result[i];

                if (status != 'OK') continue;

                const { address_components, geometry: { location }, formatted_address, types, } = place;
                const { lat, lng } = location;

                let locality = {}, administrative_area_level_1 = {};

                address_components.forEach(component => {
                    if ( isEmpty(locality) && (component.types.includes('locality') || component.types.includes('sublocality')) )
                        locality = component;
                    else if ( component.types.includes('administrative_area_level_1') )
                        administrative_area_level_1 = component;
                });

                let obj = {
                    lat,
                    lng,
                    state: administrative_area_level_1.short_name || '',
                    city: locality.long_name || '',
                    formatted_address,
                    types,
                    label: i == 0
                        ? singleLocation.pickUp ? singleLocation.pickUp.label : formatted_address
                        : singleLocation.dropOff ? singleLocation.dropOff.label : formatted_address
                }

                let additionalComponents = address_components.reduce((acum, current) => {
                    ['street_number', 'route', 'postal_code'].forEach(item=>{
                        if(current.types.includes(`${item}`)){
                            acum[item] = current.short_name || '';
                        }
                    })
                    return acum
                }, {})

                if(Object.keys(additionalComponents).length > 0){
                    obj = { ...obj, ...additionalComponents}
                }

                dispatch(setDetails( obj, pl[i] ));
            }
        }).catch((error) => {
            dispatch(setView(1));
            dispatch(errorHandler(error));
        })
    }
}

export const initializeDataAutocomplete = () => (dispatch) => {
    dispatch(getAirlineCodes());
    dispatch(getAirportCodes());
}

export const setSingleDetail = (singleDetail) => ({
    type: SET_SINGLE_DETAIL,
    singleDetail,
})

export const setDetails = (detail, key) => dispatch => {
    dispatch({ type: key.toUpperCase() + "_LIVERY_EDITION", [key]: detail });
    let isAirport = false, airportCode;
    if(detail && detail.types && detail.types.includes('airport') && key.toUpperCase() + "_LIVERY_EDITION" === FROM){
        isAirport = true;
        airportCode = dispatch(findAirport(detail));
        // airportCodes.find(item => detail.formatted_address.match(new RegExp(item.code)) !== null);
    }
    if(key.toUpperCase() + "_LIVERY_EDITION" == FROM)
        dispatch({ type: SET_IS_AIRPORT, isAirport, airportCode });

};

export const onSelectAirline = (key, form) => (dispatch, getStore) => {
    let values = airlineCodes.find(
        (item) => JSON.stringify(item._id) === JSON.stringify(key)
    );
    if (!values) {
        const codes = getStore().liveryEdition.airlineCodes;
        if (!isEmpty(codes))
            values = codes.find(
                ({ _id }) => JSON.stringify(_id) === JSON.stringify(key)
            );
    }
    dispatch(change(form, "airline", values));
};

export const clearAddresses = () => (dispatch) => {
    dispatch({ type: FROM, from: {} });
    dispatch({ type: TO, to: {} });
};

export const clearData = () => (dispatch) => {
    dispatch(setSingleDetail());
    dispatch(setFlights());
    dispatch(setInputData());
    dispatch(clearAddresses());
};

export const verifyFlight = (data) => async (dispatch, getStore) => {
    dispatch(loadingVerifier(true));
    try {
        const { from, to } = getStore().liveryEdition;

        const fromAirport =
            from && Object.keys(from).length > 0
                ? dispatch(findAirport(from))
                : undefined;
        const toAirport =
            to && Object.keys(to).length > 0
                ? dispatch(findAirport(to))
                : undefined;

        if (typeof data.airline !== 'object') {
            const { airlineCodes } = cloneDeep(getStore().liveryEdition);
            data.airline = airlineCodes.find( item =>
                item.code.trim().toLowerCase() == data.airline.trim().toLowerCase() ||
                item._id.trim() == data.airline.trim() ||
                item.label.trim() == data.airline.trim()
            );
        }

        if (!data.airline)
            throw 'Cannot find the airline';

        let result = await app.service("/api/xmlFlight").find({
            query: {
                airlineCode: data.airline.code,
                airportCode: fromAirport ? fromAirport.code : toAirport ? toAirport.code : from,
                flightNumber: data.flight,
                date_of_service: data.date,
                departure: fromAirport ? false : true,
            },
        });

        if (!result) {
            if (data.airline.sharedCodes && data.airline.sharedCodes.length > 0) {
                for (let i = 0; i < data.airline.sharedCodes.length; i += 1) {
                    if (typeof data.airline.sharedCodes[i] !== 'object') {
                        data.airline.sharedCodes[i] = await app.service('/api/airline').get(data.airline.sharedCodes[i]);
                    }
                    data.airline.sharedCodes[i].label = `${data.airline.sharedCodes[i].code} - ${data.airline.sharedCodes[i].company}`;
                    result = await app.service("/api/xmlFlight").find({
                        query: {
                            airlineCode: data.airline.sharedCodes[i].code,
                            airportCode: fromAirport ? fromAirport.code : toAirport ? toAirport.code : from,
                            flightNumber: data.flight,
                            date_of_service: data.date,
                            departure: fromAirport ? false : true,
                        },
                    });
                    if (result){
                        dispatch(change('createLivery', 'airline', data.airline.sharedCodes[i]));
                        break;
                    }
                }
            }
        }
        if (!result) {
            result = { noInfo: true };
        }

        dispatch(setFlightVerified(result));
    } catch(error){
        dispatch(setFlightVerified({ notInfo: true }));
        dispatch(errorHandler(error));
    } finally {
        dispatch(loadingVerifier(false));
    }
}

export const setCompanyCreateForm = (data) => (dispatch, getStore) => {
    if(data && data.company && data.company.noCompany){
        data.company.company = data.companyName;
    }
    localStorage.setItem('company_airline_livery_create',JSON.stringify(data.company));
    dispatch(setModalCompanySelect(false));
    dispatch(clearData());
    dispatch(push(reservationsAirlineBook));
};

export const getLogs = (liveryId, page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADING_LOGS_LIVERY_EDITION, loading: true });
    app.service("/api/livery-log")
        .find({
            query: {
                $skip: (page - 1) * 10,
                liveryId,
                isAlert: { $ne: true },
                $sort: { _id: -1 },
                $populate: ["user"],
            },
        })
        .then((response) => {
            dispatch({ type: LIST_LOGS_LIVERY_EDITION, dataLogs: response });
            dispatch({ type: PAGE_LOGS_LIVERY_EDITION, pageLogs: page });
        })
        .catch((error) => {
            dispatch(errorHandler(error));
        })
        .finally(() => {
            dispatch({ type: LOADING_LOGS_LIVERY_EDITION, loading: false });
        });
};

export const setView = (view) => (dispatch) => dispatch(setViewAction(view));
export const loadingVerifier = (loading) => ({
    type: SET_LOADING_VERIFIER,
    loading,
});
export const setFlightVerified = (xmlFlightVerified) => ({
    type: SET_FLIGHT_VERIFIED,
    xmlFlightVerified,
});
const useVerifyFlight = () => (dispatch, getStore) => {
    let { xmlFlightVerified, from } = getStore().liveryEdition;
    const fromAirport =
        from && Object.keys(from).length > 0
            ? dispatch(findAirport(from))
            : undefined;
    if (xmlFlightVerified && !xmlFlightVerified.noInfo) {
        xmlFlightVerified.selected = {
            departure: xmlFlightVerified.departure.actual || xmlFlightVerified.departure.updated,
            arrival: xmlFlightVerified.arrival.actual || xmlFlightVerified.arrival.updated,
        };
        if (fromAirport) {
            const time = xmlFlightVerified.selected.arrival.time.split(":");
            dispatch(change("createLivery", 'time', { hour: time[0], minutes: time[1] }));
        }
    }
    dispatch(change("createLivery",'xmlFlightVerified', xmlFlightVerified));
};
const useOriginalVerifyFlight = () => (dispatch, getStore) => {
    let { xmlFlightVerified, from } = getStore().liveryEdition;
    const fromAirport =
        from && Object.keys(from).length > 0
            ? dispatch(findAirport(from))
            : undefined;
    if (xmlFlightVerified && !xmlFlightVerified.noInfo) {
        xmlFlightVerified.selected = {
            departure: xmlFlightVerified.departure.scheduled,
            arrival: xmlFlightVerified.arrival.scheduled,
        };
        if (fromAirport) {
            const time = xmlFlightVerified.selected.arrival.time.split(":");
            dispatch(change("createLivery", 'time', { hour: time[0], minutes: time[1] }));
        }
    }
    dispatch(change('createLivery', 'xmlFlightVerified', xmlFlightVerified));
};

export const setModalLogs = (modalLogs) => (dispatch) => {
    dispatch({
        type: MODAL_LOGS,
        modalLogs,
    });
};

export const actions = {
    setView,
    getFlights,
    getFlightById,
    getSingleDetails,
    setCompanyCreateForm,
    initializeDataAutocomplete,
    initializePickDropForm,
    clearData,
    getLogs,
    setModalLogs,
    clearAddresses,
    verifyFlight,
    useVerifyFlight,
    useOriginalVerifyFlight,
    cancelTripLivery,
    createLiveryTrip,
    updateTripLivery,
    setDetails,
    onSelectAirline,
}

const setAirlineCodes = (airlineCodes) => ({
    type: SET_AIRLINE_CODES,
    airlineCodes,
});

const setAirportCodes = (airportCodes) => ({
    type: SET_AIRPORT_CODES,
    airportCodes,
});

export const setFlights = flights => ({
    type: SET_FLIGHTS,
    flights,
});

const setViewAction = view => ({
    type: SET_VIEW,
    view
})

export const setModalCompanySelect = (modalCompanySelect) => ({
    type: MODAL_COMPANY_SELECT,
    modalCompanySelect,
})

export const setInputData = inputData => ({
    type: SET_INPUT_DATA,
    inputData,
})

// Reducers
const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_VIEW]: (state, { view }) => ({ ...state, view }),
    [SET_FLIGHTS]: (state, { flights }) => ({ ...state, flights }),
    [SET_INPUT_DATA]: (state, { inputData }) => ({ ...state, inputData }),
    [SET_SINGLE_DETAIL]: (state, { singleDetail }) => ({ ...state, singleDetail }),
    [SET_AIRLINE_CODES]: (state, { airlineCodes }) => ({ ...state, airlineCodes }),
    [SET_AIRPORT_CODES]: (state, { airportCodes }) => ({ ...state, airportCodes }),
    [SET_IS_AIRPORT]: (state, { isAirport, airportCode }) => ({ ...state, isAirport, airportCode }),
    [FROM]: (state, { from }) => ({ ...state, from }),
    [TO]: (state, { to }) => ({ ...state, to }),
    [MODAL_COMPANY_SELECT]: (state, { modalCompanySelect }) => ({ ...state, modalCompanySelect }),
    [LIST_LOGS_LIVERY_EDITION]: (state, { dataLogs }) => ({ ...state, dataLogs }),
    [LOADING_LOGS_LIVERY_EDITION]: (state, { loading }) => ({ ...state, loadingLogs: loading }),
    [PAGE_LOGS_LIVERY_EDITION]: (state, { pageLogs }) => ({ ...state, pageLogs }),
    [SET_LOADING_VERIFIER]: (state, { loading }) => ({ ...state, loadingVerifier: loading }),
    [SET_FLIGHT_VERIFIED]: (state, { xmlFlightVerified }) => ({ ...state, xmlFlightVerified }),
    [MODAL_LOGS]: (state, { modalLogs }) => ({ ...state, modalLogs }),
};

export const initialState = {
    from: {},
    to: {},
    loading: false,
    loadingLogs: false,
    loadingVerifier: false,
    pageLogs: 1,
    dataLogs: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    view: 1,
    flights: undefined,
    inputData: undefined,
    singleDetail: undefined,
    airportCodes: [],
    airlineCodes: [],
    isAirport: false,
    modalLogs: false,
    airportCode: undefined,
    modalCompanySelect: false,
    xmlFlightVerified: {},
};

export default handleActions(reducers, initialState);
