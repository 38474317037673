import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderFieldCheck, renderNumber, renderSelectField } from "../../../Utils/renderField/renderField";


const Form = ({
    handleSubmit,
    cancel,
 }) => (
    <form onSubmit={handleSubmit}>
        <div className="form-group grid-container">
            <div className="grid-titulo padding-15">
                <div className="padding-15 p-sm-0 py-sm-1">
                    <div className="row">
                        <div className="form-group col-12">
                            <strong htmlFor="company"> Company Name </strong>
                            <Field
                                name="company"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <strong htmlFor="odoo_reference_id"> Odoo reference id </strong>
                            <Field
                                name="odoo_reference_id"
                                component={renderField}
                                type="odoo_reference_id"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12 col-lg-6">
                            <strong htmlFor="crewOps"> Crew Ops Phone </strong>
                            <Field
                                name="crewOps"
                                component={renderNumber}
                                formatTemplate="(###) ###-####"
                            />
                        </div>
                        <div className="form-group col-12 col-lg-6">
                            <strong htmlFor="zip"> Livery Customer ID </strong>
                            <Field
                                name="liveryId"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12 col-lg-6">
                            <strong htmlFor="code"> IATA Code </strong>
                            <Field
                                name="code"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12 col-lg-6 d-flex flex-column justify-content-end align-items-end">
                            <Field
                                name="has_aoa"
                                component={renderFieldCheck}
                                label="AOA Requirement"
                            />
                            <Field
                                name="has_cgo"
                                component={renderFieldCheck}
                                label="CGO Requirement"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row">
                                <button className="btn btn-primary m-1 px-4" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
);

const AirlineForm = reduxForm({
    form: "airlineForm",
    validate: (data, props) =>{
        const errors = {};
        if(!data.company) errors.company = "Required field";
        if(!data.crewOps) errors.crewOps = "Required field";
        if(!data.code) errors.code = "Required field";
        if(!data.liveryId) errors.liveryId = "Required field";
        return errors;
    }
})(Form);

export default AirlineForm;
