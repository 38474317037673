import React, { PureComponent } from "react";
import { Link, NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import PropTypes from "prop-types";

import "./navbar.css";
import "./burger-sidebar.css";
import app from "../../../libs/apiClient";
import { clientTypes, notificationsFor } from "../../utility/constants";

import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import logoSrc from '../../../../assets/img/white-logo.png';
import {Icons} from '../../icons/menu';
import IconBell from '../../icons/bell';

import {
    marketingBlogPosts,
    marketingPromoCodes,
    hrApplications,
    reportsRevenue,
    reportsTripReport,
    reservationsAirline,
    reservationsCharter,
    reservationsShuttles,
    reservationsTours,
    settingsAccounts,
    settingsAccountsCompanies,
    settingsAirlines,
    settingsRates,
    settingsTours,
    settingsUserAccounts,
    resrvationsExpenseTickets,
} from "../../../routesConfiguration/paths";

import Breadcrumb from "../Breadcrumb";

const [, company] = clientTypes;
const [newCompanies] = notificationsFor;

class Navbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            newCompanies: 0,
        };

        this.getNotifications = this.getNotifications.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
        this.getNotifications();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.getNotifications();
            this.setState({ isMenuOpen: false });
        }
    }

    getNotifications() {
        const {
            userInfo,
            userInfo: { notifications = [] },
        } = this.props;

        const notification = notifications.find(
            ({ notificationFor }) => notificationFor === newCompanies
        );

        if (!notification) {
            app.service("user").patch(userInfo._id, {
                $push: {
                    notifications: {
                        notificationFor: newCompanies,
                        lastNotified: new Date(),
                    },
                },
            }, {
                query: {
                    $populate: ['permission']
                }
            }).then((userInfo) => {
                this.props.setInfoUser(userInfo)
            });

            return;
        }

        app.service("api/client")
            .find({
                query: {
                    $limit: 0,
                    userType: company,
                    createdAt: { $gte: notification.lastNotified },
                },
            })
            .then((res) => {
                this.setState({ newCompanies: res.total });
            })
            .catch(console.error);
    }

    handleStateChange(state) {
        this.setState({ isMenuOpen: state.isOpen });
    }

    toggleMenu() {
        return (e) => {
            this.setState((prevState) => ({
                isMenuOpen: !prevState.isMenuOpen,
            }));
        };
    }

    logOut(event) {
        this.props.logOut();
    }

    render() {

        const { isMenuOpen, newCompanies } = this.state;
        const {  userInfo: { permission, superUser }, location } = this.props;

        return (
            <div>
                <header className="topnavbar-wrapper">
                    <nav className="navbar topnavbar">
                        <Breadcrumb location={location} />
                        <div className="nav-wrapper ">
                            <ul className="nav navbar-nav" />
                        </div>
                    </nav>
                </header>
                <Menu
                    isOpen={isMenuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                >
                    <div className="nav-wrapper bs-nav">
                        <Accordion allowZeroExpanded >
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="py-4 px-3">
                                    <img src={logoSrc} className="w-100 p-3"/>
                                </div>
                            </div>
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <NavLink to="/" exact className="nav-link" id="locations" onClick={this.toggleMenu()}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <Icons.IconHome className="menu_icon"/>&nbsp;&nbsp;
                                                            <span> Home </span>
                                                        </div>
                                                    </NavLink>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <div className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <Icons.IconReservations className="menu_icon" />&nbsp;&nbsp;
                                                        <span className={expanded ? 'bold-sidebar': `bold-sidebar `}>Reservations </span>
                                                        <span className="caret">
                                                        {expanded
                                                            ? <Icons.IconCaretUp />
                                                            : <Icons.IconCaretDown />
                                                        }
                                                        </span>
                                                    </div>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemState>
                                        {
                                            ({expanded})=>(
                                                <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to={reservationsAirline} onClick={this.toggleMenu()}>
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span>Airline</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to={reservationsCharter} onClick={this.toggleMenu()}>
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span>Charter</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    {permission && permission.find(({ tourReservations }) => tourReservations !== 'none') && (
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to={reservationsTours} onClick={this.toggleMenu()}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span>Tour</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to={reservationsShuttles} onClick={this.toggleMenu()}>
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span>Shared-Ride</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to={resrvationsExpenseTickets} onClick={this.toggleMenu()}>
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span>Expense Tickets</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </AccordionItemPanel>
                                            )
                                        }
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                            {permission && permission.find(({ reports }) => reports !== 'none') && (
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                            <Icons.IconReports className="menu_icon" />&nbsp;&nbsp;
                                                            <span className={expanded ? 'bold-sidebar': `bold-sidebar `}>Reports </span>
                                                            <span className="caret">
                                                            {expanded
                                                                ? <Icons.IconCaretUp />
                                                                : <Icons.IconCaretDown />
                                                            }
                                                            </span>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>

                                        <AccordionItemState>
                                            {
                                                ({expanded})=>(
                                                    <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to={reportsRevenue} onClick={this.toggleMenu()}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span>Revenue</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to={reportsTripReport} onClick={this.toggleMenu()}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span>Trip Report</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </AccordionItemPanel>
                                                )
                                            }
                                        </AccordionItemState>
                                    </AccordionItem>
                                </div>
                            )}
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <Icons.IconMarketing className="menu_icon" />&nbsp;&nbsp;
                                                        <span className={expanded ? ' bold-sidebar': `bold-sidebar `}>Marketing </span>
                                                        <span className="caret">
                                                        {expanded
                                                            ? <Icons.IconCaretUp />
                                                            : <Icons.IconCaretDown />
                                                        }
                                                        </span>
                                                    </div>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemState>
                                        {
                                            ({expanded})=>(
                                                <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                    {permission && permission.find(({ promo }) => promo !== 'none') && (
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to={marketingPromoCodes} onClick={this.toggleMenu()}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span>Promo Codes</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    {permission && permission.find(({ blogPost }) => blogPost !== 'none') && (
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to={marketingBlogPosts} onClick={this.toggleMenu()}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span>Blog Post</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                </AccordionItemPanel>
                                            )
                                        }
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <Icons.IconHr className="menu_icon" />&nbsp;&nbsp;
                                                        <span className={expanded ? ' bold-sidebar': `bold-sidebar `}>HR </span>
                                                        <span className="caret">
                                                        {expanded
                                                            ? <Icons.IconCaretUp />
                                                            : <Icons.IconCaretDown />
                                                        }
                                                        </span>
                                                    </div>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemState>
                                        {
                                            ({expanded})=>(
                                                <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to={hrApplications} onClick={this.toggleMenu()}>
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span>Applications</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </AccordionItemPanel>
                                            )
                                        }
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                            {permission && (permission.length === 0 || permission.find(({ administration }) => administration !== 'none')) && (
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                            <Icons.IconAdmin className="menu_icon" />&nbsp;&nbsp;
                                                            <span className={expanded ? ' bold-sidebar': `bold-sidebar `}>Settings </span>
                                                            <span className="caret">
                                                            {expanded
                                                                ? <Icons.IconCaretUp />
                                                                : <Icons.IconCaretDown />
                                                            }
                                                            </span>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemState>
                                            {
                                                ({expanded})=>(
                                                    <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                        {permission && permission.find(({ tours }) => tours !== 'none') && (
                                                            <div className="sub-nav-item">
                                                                <NavLink exact to={settingsTours} onClick={this.toggleMenu()}>
                                                                    <div className="d-inline-block item-icon-wrapper">
                                                                        <span>Tours</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {permission && permission.find(({ companies, clients, points }) => companies !== 'none' || clients !== 'none' || points !== 'none') && (
                                                            <div className="sub-nav-item">
                                                                <NavLink exact to={settingsAccounts} onClick={this.toggleMenu()}>
                                                                    <div className="d-inline-block item-icon-wrapper">
                                                                        <span>Customers</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {permission && permission.find(({ vehicles, locations, aditionalCharges }) => vehicles !== 'none' || locations !== 'none' || aditionalCharges !== 'none') && (
                                                            <div className="sub-nav-item">
                                                                <NavLink exact to={settingsRates} onClick={this.toggleMenu()}>
                                                                    <div className="d-inline-block item-icon-wrapper">
                                                                        <span>Rates</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {permission && permission.find(({ airlineCrew }) => airlineCrew !== 'none') && (
                                                            <div className="sub-nav-item">
                                                                <NavLink exact to={settingsAirlines} onClick={this.toggleMenu()}>
                                                                    <div className="d-inline-block item-icon-wrapper">
                                                                        <span>Airline Crew</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {superUser && (
                                                            <div className="sub-nav-item">
                                                                <NavLink exact to={settingsUserAccounts} onClick={this.toggleMenu()}>
                                                                    <div className="d-inline-block item-icon-wrapper">
                                                                        <span>User Accounts</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                    </AccordionItemPanel>
                                                )
                                            }
                                        </AccordionItemState>
                                    </AccordionItem>
                                </div>
                            )}
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <a href="/#/" onClick={this.logOut} className="nav-link">
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <Icons.IconLogOut className="menu_icon" />&nbsp;&nbsp;
                                                            <span> Log Out </span>
                                                        </div>
                                                    </a>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                            {!!newCompanies && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => (
                                                    <NavLink exact to={settingsAccountsCompanies} className="nav-link" onClick={this.toggleMenu()}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <IconBell className="menu_icon"/>&nbsp;&nbsp;
                                                            <span> Companies&nbsp;</span>
                                                            <span className="badge badge-pill badge-light">
                                                                {newCompanies}
                                                            </span>
                                                        </div>
                                                    </NavLink>
                                                )}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                            )}
                        </Accordion>
                    </div>
                </Menu>
            </div>
        );
    }
}

Navbar.propTypes = {
    url: PropTypes.string,
    logOut: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};

export default Navbar;
