import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderDatePicker } from '../../Utils/renderField/renderField';

const LoadDatesForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-1">
        <div className="col-6 mt-3 form-group">
          <strong>Start Date</strong>
          <Field
            name="startDate"
            component={renderDatePicker}
            type="date"
            defaultValue="2027-12-01"
            className="p-1"
            numberOfMonths={1}
            isOutsideRange={false}
          />
        </div>
        <div className="col-6 mt-3 form-group">
            <strong>End Date</strong>
          <Field
            name="endDate"
            component={renderDatePicker}
            type="date"
            className="p-1"
            defaultValue="2027-12-31"
            numberOfMonths={1}
            isOutsideRange={false}
          />
        </div>
      </div>

      <div className="mb-3">
        <button type="submit" className="btn btn-lg w-100" style={{backgroundColor:'#D50032', color:'white'}}>LOAD</button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'loadDatesForm' 
})(LoadDatesForm);
